import React from "react";
import Text from "../../components/design-system/Text";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Wrap = styled.div<any>`
  position: relative;
  background-image: url("/img/standbuy/background/nova/background-blob-service.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export default function NovaLandingPage() {
  const navigate = useNavigate();

  return (
    <Wrap className="flex flex-col items-center justify-center h-screen bg-black">
      <img
        src="/img/standbuy/logo/stepbuy.svg"
        className="cursor-pointer"
        alt="nova-landing"
        onClick={() => navigate("/nova-test")}
      />

      <div className="flex flex-col items-center justify-center mt-10">
        <Text color="#ffffff" fontSize={24} fontWeight={400}>
          아시아ver. 생성형 AI
        </Text>
        <Text color="#ffffff" fontSize={24} fontWeight={700}>
          ONE STEP 스탭바이
        </Text>
      </div>

      <div className="h-[1px] w-[50%] bg-white my-20" />

      <img src="/img/standbuy/logo/nova-sb-logo.svg" alt="nova-landing" />

      <div className="flex flex-col items-center justify-center mt-10">
        <Text color="#ffffff" fontSize={24} fontWeight={400}>
          50만 한국 콘텐츠 콘텐츠
        </Text>
        <Text color="#ffffff" fontSize={24} fontWeight={700}>
          콘텐츠 자유거래 마켓 스탠바이
        </Text>
      </div>
    </Wrap>
  );
}
