import React from "react";
import Text from "../../../../components/design-system/Text";

export default function NovaYearPlan({
  date,
  price,
  type,
  detail = [],
  onClick,
}: {
  date: string;
  price: string;
  type: string;
  detail: string[];
  onClick?: () => void;
}) {
  return (
    <div className="relative mt-10 flex gap-4">
      <div className="bg-darkBg min-w-[280px] h-[400px] py-4 px-6 rounded-xl">
        <Text fontSize={48} color="white" fontWeight={700}>
          {type}
        </Text>
        <div className="flex gap-2 items-end">
          <Text color="white">{price}</Text>
          <Text color="white" fontSize={12}>
            /{date}
          </Text>
        </div>
        <div className="mt-10 flex flex-col gap-2">
          {detail?.map((item, index) => (
            <Text key={index} color="white">
              ☑️ {item}
            </Text>
          ))}
        </div>
        <div className="absolute bottom-6 right-10">
          <Text color="white" fontSize={20} fontWeight={700}>
            구독 &gt;
          </Text>
        </div>
      </div>

      {type === "Pro" && (
        <img
          src="/img/standbuy/nova/recommand.png"
          alt=""
          width={50}
          className="absolute top-0 right-6"
        />
      )}
    </div>
  );
}
