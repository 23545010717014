import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { NovaHeader } from "./card/NovaHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaste } from "@fortawesome/free-solid-svg-icons";
import Text from "../../components/design-system/Text";
import StockText from "../../components/design-system/StockText";
import { useLocation, useNavigate } from "react-router-dom";
import NovaCreateImg from "./NovaCreateImg";

const Wrap = styled.div<any>`
  position: relative;
  background-image: url("/img/standbuy/background/nova/background-blob-service.png");
  background-size: cover;
  background-position: center;
`;

const ResultBox = styled.div<any>`
  position: relative;
  background-image: url("/img/standbuy/nova/end-bg.png");
  background-size: cover;
  background-position: center;
`;

export default function NovaTtiEndPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowImage(true);
    }, 10000); // 10 seconds

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Wrap className="relative min-h-screen flex flex-col items-center bg-black pb-10">
      <NovaHeader />

      {!showImage ? (
        <img
          src="/img/standbuy/nova/112.gif"
          alt="nova-ip-end"
          width={800}
          className="flex items-center transform translate-y-1/2"
        />
      ) : (
        <>
          <div className="relative w-full mt-[120px]">
            <div className="absolute left-[30px] flex items-center gap-2 ml-40">
              <img
                src="/img/standbuy/nova/double-arrow-left.svg"
                alt="nova-tool-bg"
                onClick={() => navigate(-1)}
              />
              <Text
                onClick={() => navigate(-1)}
                className="cursor-pointer "
                color="white"
                fontSize={20}
                fontWeight={700}
              >
                돌아가기
              </Text>
            </div>
          </div>

          <ResultBox className="w-[1000px] bg-[#161616] pt-10 pb-20 px-8 mt-10 rounded-2xl relative flex gap-1 justify-between">
            <div className="flex flex-col">
              <div className="flex flex-col items-start gap-2">
                <div className="flex gap-2">
                  <Text
                    className="bg-[#ffffff] px-2 rounded-3xl mb-[4px] "
                    fontSize={10}
                    fontWeight={500}
                  >
                    Image
                  </Text>

                  <Text
                    className="bg-[#ffffff] px-2 rounded-3xl mb-[4px] "
                    fontSize={10}
                    fontWeight={500}
                  >
                    16:9
                  </Text>
                </div>
                <Text fontSize={24} fontWeight={700} color="white" className="">
                  생성된 이미지
                </Text>

                <Text
                  className="rounded-3xl mb-[4px] "
                  fontSize={10}
                  fontWeight={500}
                  color="#CECECE"
                >
                  2025.02.18 16:47
                </Text>
              </div>

              <div
                className="mt-2 flex items-end gap-2 max-w-[340px] w-[240px] max-h-[150px] cursor-pointer"
                // onClick={() => handleCopyClipBoard(group[0])}
              >
                <StockText
                  color={"#CECECE"}
                  fontSize={14}
                  className=" overflow-hidden break-words text-ellipsis line-clamp-3 "
                >
                  20대 남매가 서로 다툰다. 그들은 서로 주먹을 휘두르고 있다.
                  서로의 눈은 서로를 째려보고 있다.
                </StockText>
                {/* <FontAwesomeIcon
                  icon={faPaste}
                  color="white"
                  className="mb-1"
                /> */}
              </div>
            </div>

            <div className="flex flex-col items-end">
              <div className="flex flex-wrap  justify-center">
                <div
                  className="relative group"
                  style={{ width: "200px", height: "200px" }}
                >
                  <img
                    src={"/img/standbuy/nova/text-to-2.jpeg"}
                    className="rounded-lg cursor-pointer"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity rounded-lg pointer-events-none"></div>
                  <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity flex gap-2 pointer-events-none">
                    {/* <button className="bg-white p-1 rounded pointer-events-auto">
                      Button 1
                    </button> */}
                    {/* <button
                            className="bg-white p-1 rounded pointer-events-auto"
                            onClick={() => {
                              fileDownload(item.genId);
                            }}
                          >
                            파일 다운로드
                          </button> */}
                  </div>
                </div>
                );
              </div>

              <img
                src="/img/standbuy/nova/img-download.png"
                alt="nova-ip-end"
                width={20}
                className="cursor-point mt-2 mr-[10px]"
              />
            </div>

            <div className="flex flex-col items-end">
              <div className="flex flex-wrap  justify-center">
                <div
                  className="relative group"
                  style={{ width: "200px", height: "200px" }}
                >
                  <img
                    src={"/img/standbuy/nova/text-to-1.jpeg"}
                    className="rounded-lg cursor-pointer"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity rounded-lg pointer-events-none"></div>
                  <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity flex gap-2 pointer-events-none">
                    {/* <button className="bg-white p-1 rounded pointer-events-auto">
                      Button 1
                    </button> */}
                    {/* <button
                            className="bg-white p-1 rounded pointer-events-auto"
                            onClick={() => {
                              fileDownload(item.genId);
                            }}
                          >
                            파일 다운로드
                          </button> */}
                  </div>
                </div>
                );
              </div>

              <img
                src="/img/standbuy/nova/img-download.png"
                alt="nova-ip-end"
                width={20}
                className="cursor-point mt-2 mr-[10px]"
              />
            </div>

            <div className="flex flex-col items-end">
              <div className="flex flex-wrap justify-center">
                <div
                  className="relative group"
                  style={{ width: "200px", height: "200px" }}
                >
                  <img
                    src={"/img/standbuy/nova/text-to-3.jpeg"}
                    className="rounded-lg cursor-pointer"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity rounded-lg pointer-events-none"></div>
                  <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity flex gap-2 pointer-events-none">
                    {/* <button className="bg-white p-1 rounded pointer-events-auto">
                      Button 1
                    </button> */}
                    {/* <button
                            className="bg-white p-1 rounded pointer-events-auto"
                            onClick={() => {
                              fileDownload(item.genId);
                            }}
                          >
                            파일 다운로드
                          </button> */}
                  </div>
                </div>
                );
              </div>

              <img
                src="/img/standbuy/nova/img-download.png"
                alt="nova-ip-end"
                width={20}
                className="cursor-point mt-2 mr-[10px]"
              />
            </div>

            <div className="absolute bottom-8 right-10 flex items-center gap-2">
              <button className="bg-[#ffffff] px-4 py-1 rounded-2xl flex items-center gap-1 w-[140px] justify-center">
                <Text fontSize={14} fontWeight={700} color="black">
                  다시 생성하기
                </Text>
                <img src="/img/standbuy/nova/reload.svg" alt="" width={20} />
              </button>
            </div>
          </ResultBox>
        </>
      )}

      <NovaCreateImg
      // imgList={imgList}
      // setImgList={setImgList}
      // isLoading={isLoading}
      // setIsLoading={setIsLoading}
      // controlMode={controlMode}
      // setControlMode={setControlMode}
      // controlStrength={controlStrength}
      // setControlStrength={setControlStrength}
      // quality={quality}
      // setQuality={setQuality}
      // cfgScale={cfgScale}
      // setCfgScale={setCfgScale}
      // seed={seed}
      // setSeed={setSeed}
      // testFlag={testFlag}
      // setTestFlag={setTestFlag}
      />
    </Wrap>
  );
}
