import { NovaHeader } from "./card/NovaHeader";
import Text from "../../components/design-system/Text";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import NovaCreateTool from "./card/NovaCreateTool";
import NovaCreateImg from "./NovaCreateImg";
import NovaCreateToolMulti from "./card/NovaCreateToolMulti";
import NovaCreateImgMulti from "./NovaCreateImgMulti";
const Wrap = styled.div<any>`
  position: relative;
  background-image: url("/img/standbuy/background/nova/background-blob-service.png");
  background-size: cover;
  /* 
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.4;
    z-index: 1;
  } */
`;

export default function NovaPageMulti() {
  const navigate = useNavigate();

  return (
    <Wrap className="min-h-screen flex flex-col items-center justify-center bg-black pb-10">
      <NovaHeader />

      <div className="mt-[120px] flex gap-10">
        <div
          className="relative cursor-pointer"
          onClick={() => navigate("/nova-ip")}
        >
          <img
            src="/img/standbuy/nova/face-change.png"
            alt="nova-blob"
            className="w-[320px] h-[240px] rounded-t-[48px]"
          />
          <div className="bg-[#1B1A29] rounded-b-[48px] p-10 flex flex-col gap-1 pl-[40px]">
            <div className="flex flex-col">
              <Text fontSize={24} fontWeight={700} color="white">
                사진 속 인물
              </Text>
              <Text fontSize={24} fontWeight={700} color="white">
                얼굴 바꾸기
              </Text>
            </div>

            <div className="flex flex-col gap-1 mt-4">
              <Text fontSize={18} fontWeight={500} color="white">
                사진 속 인물의 얼굴을
              </Text>

              <Text fontSize={18} fontWeight={500} color="white">
                선택한 얼굴로 변경할 수 있습니다.
              </Text>

              <Text fontSize={18} fontWeight={500} color="white">
                (최대 4명)
              </Text>
            </div>
          </div>

          <div className="absolute inset-0 bg-black bg-opacity-50 rounded-t-[48px] opacity-0 hover:opacity-100 transition-opacity duration-300"></div>
        </div>

        <div className="relative">
          <img
            src="/img/standbuy/nova/item.png"
            alt="nova-blob"
            className="w-[320px] h-[240px] rounded-t-[48px]"
          />
          <div className="bg-[#1B1A29] rounded-b-[48px] p-10 flex flex-col gap-1 pl-[40px]">
            <div className="flex flex-col">
              <Text fontSize={24} fontWeight={700} color="white">
                이미지 생성
              </Text>
              <Text fontSize={24} fontWeight={700} color="white">
                (텍스트 기반)
              </Text>
            </div>

            <div className="flex flex-col gap-1 mt-4">
              <Text fontSize={18} fontWeight={500} color="white">
                사진 속 인물의 얼굴을 선택한
              </Text>

              <Text fontSize={18} fontWeight={500} color="white">
                선택한 얼굴로 변경할 수 있습니다.
              </Text>

              <Text fontSize={18} fontWeight={500} color="white">
                (최대 4명)
              </Text>
            </div>
          </div>

          <div className="absolute inset-0 bg-black bg-opacity-50 rounded-t-[48px] opacity-0 hover:opacity-100 transition-opacity duration-300"></div>
        </div>

        <div className="relative">
          <img
            src="/img/standbuy/nova/item.png"
            alt="nova-blob"
            className="w-[320px] h-[240px] rounded-t-[48px]"
          />
          <div className="bg-[#1B1A29] rounded-b-[48px] p-10 flex flex-col gap-1 pl-[40px]">
            <div className="flex flex-col">
              <Text fontSize={24} fontWeight={700} color="white">
                사진 속 인물
              </Text>
              <Text fontSize={24} fontWeight={700} color="white">
                얼굴 바꾸기
              </Text>
            </div>

            <div className="flex flex-col gap-1 mt-4">
              <Text fontSize={18} fontWeight={500} color="white">
                사진 속 인물의 얼굴을
              </Text>

              <Text fontSize={18} fontWeight={500} color="white">
                선택한 얼굴로 변경할 수 있습니다.
              </Text>

              <Text fontSize={18} fontWeight={500} color="white">
                (최대 4명)
              </Text>
            </div>
          </div>

          <div className="absolute inset-0 bg-black bg-opacity-50 rounded-t-[48px] opacity-0 hover:opacity-100 transition-opacity duration-300"></div>
        </div>
      </div>
      <NovaCreateImgMulti />
    </Wrap>
  );
}
