import React, { useEffect } from "react";
import { motion } from "framer-motion";
import Text from "../../components/design-system/Text";
import SideDrawer from "../../components/design-system/SideDrawer";
import MyPageEdit from "./card/MyPageEdit";
import MyBankEdit from "./card/MyBankEdit";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import GoogleAd from "../../components/ads/GoogleAd";
import { UploadTextColor } from "../../styles/color";
import { useLocation } from "react-router-dom";
import { MetaDatas } from "../../meta/MetaDatas";
import { useContext } from "../../util/useContext";
import { useMediaQuery } from "react-responsive";
import MobileFooter from "../../components/layout/footer/MobileFooter";
import MyPageLayout from "../../components/layout/MyPageLayout";
import { useMutation } from "@tanstack/react-query";
import { signOutLogin } from "../../api/login/login";
import { signOut } from "../../lib/modules/auth";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { toastFetched } from "../../store/reducer/toastSlice";
import { shallowEqual } from "react-redux";

export default function MyPage() {
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const { isLogin, me } = useContext();
  const { accessToken, refreshToken } = useAppSelector((state) => state.auth);
  const browserData = useAppSelector(
    (state) => state.browser.browser,
    shallowEqual
  );
  const dispatch = useAppDispatch();

  const { mutate } = useMutation({
    mutationFn: signOutLogin,
    onSuccess: (data) => {
      if (data?.success) {
        signOut({ dispatch: dispatch });
      } else {
        dispatch(
          toastFetched({
            show: true,
            text: data?.error?.message,
            type: "error",
          })
        );
      }
    },
  });

  return (
    <>
      <MetaDatas
        title={"마이페이지"}
        siteName={""}
        siteUrl={location.pathname}
      />
      <MyPageLayout>
        <div className="flex justify-between">
          <div className="w-full max-w-[1280px] mx-auto relative">
            <div
              className={`flex flex-col gap-2 mb-[30px] ${isMobile ? "px-6" : ""}`}
            >
              <Text className="title ">
                {isMobile ? "내 정보 수정" : me?.nickname + " 님, 안녕하세요."}
              </Text>
              <div className="flex justify-between">
                <Text fontSize={14} color="black">
                  내 정보를 확인하고 수정할 수 있어요.
                </Text>
                <Text
                  fontSize={15}
                  color="#9E9E9E"
                  fontWeight={700}
                  className="cursor-pointer "
                  onClick={() => {
                    if (isLogin) {
                      mutate({
                        accessToken: accessToken,
                        refreshToken: refreshToken,
                        browser: browserData,
                      });
                    }
                  }}
                >
                  로그아웃
                </Text>
              </div>
            </div>

            <div className="h-[2px] bg-[#F5F5F5] mb-[30px] mt-[10px] w-full" />

            <MyPageEdit />

            <Text
              color={UploadTextColor}
              className="px-4 mt-20 flex justify-center "
              fontSize={15}
            >
              *하단 광고는 STANDBUY와 무관한 광고 입니다.
            </Text>

            <hr className="w-full my-1 border-gray-300 border-[1px] " />

            <div className="w-full sm:max-w-[728px] mx-auto my-5">
              <GoogleAd />
            </div>
          </div>
        </div>
      </MyPageLayout>
      {isMobile ? <MobileFooter /> : <CustomFooter styles={"fixed bottom-0"} />}
    </>
  );
}
