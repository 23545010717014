// GoogleAd.js
import React, { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
const GoogleAd = () => {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const adRef = useRef(null);

  useEffect(() => {
    if (window.adsbygoogle && adRef.current) {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (e) {
        console.error("Ad error: ", e);
      }
    }
  }, []);

  return (
    <div
      className="googleAd-container"
      style={{ minWidth: isMobile ? "240px" : "auto" }}
    >
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-1049694803672930"
        data-ad-slot="9250904794"
        data-ad-format={"horizontal"}
        data-full-width-responsive="true"
        ref={adRef}
      ></ins>
    </div>
  );
};

export default GoogleAd;
