import { useNavigate } from "react-router-dom";
import NovaCreateTool from "./card/NovaCreateTool";
import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { novaPrompt, stPrompt } from "../../api/nova";
import { useAppDispatch } from "../../store/store";
import { toastFetched } from "../../store/reducer/toastSlice";
import NovaCreateToolSt from "./card/NovaCreateToolSt";

export default function NovaCreateImgSt({
  imgList,
  setImgList,
  isLoading,
  setIsLoading,
  controlMode,
  setControlMode,
  controlStrength,
  setControlStrength,
  quality,
  setQuality,
  cfgScale,
  setCfgScale,
  seed,
  setSeed,
}: any) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [text, setText] = useState(""); // 입력된 텍스트 상태
  // const [isLoading, setIsLoading] = useState(false); // 새 블록 추가 감지
  const [selectedRatio, setSelectedRatio] = useState("16:9");
  const [files, setFiles] = useState<[]>([]);
  const [imgCount, setImgCount] = useState(2);
  const [fileUrl, setFileUrl] = useState<any>();

  const { mutate, status } = useMutation({
    mutationFn: stPrompt,
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (data) => {
      if (data?.success) {
        setImgList((prev: any) => [
          [
            text,
            data?.data.map((item: any) => ({
              image: item.image,
              genId: item.gen_id, // Store both image and gen_id
            })),
          ],
          ...prev,
        ]);
        setIsLoading(false);
      } else {
        setImgList([]);
        setIsLoading(false);
        dispatch(
          toastFetched({
            show: true,
            text: data?.error?.message,
            type: "error",
          })
        );
      }
    },
  });

  return (
    <div className="flex flex-col items-center ">
      <NovaCreateToolSt
        //생성 버튼 클릭
        mutate={mutate}
        //프롬프트 입력
        text={text}
        setText={setText}
        //생성 상태
        status={status}
        //해상도 선택
        selectedRatio={selectedRatio}
        setSelectedRatio={setSelectedRatio}
        //이미지 파일 업로드
        files={files}
        setFiles={setFiles}
        //이미지 개수
        imgCount={imgCount}
        setImgCount={setImgCount}
        controlMode={controlMode}
        setControlMode={setControlMode}
        controlStrength={controlStrength}
        setControlStrength={setControlStrength}
        quality={quality}
        setQuality={setQuality}
        cfgScale={cfgScale}
        setCfgScale={setCfgScale}
        seed={seed}
        setSeed={setSeed}
      />
    </div>
  );
}
