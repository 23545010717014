import React from "react";
import { Helmet } from "react-helmet-async";

interface IProps {
  siteName: string;
  title: string;
  siteUrl: string;
  keywords?: any;
}

export function MetaDatas({ title, siteUrl, keywords }: IProps): JSX.Element {
  return (
    <Helmet>
      <title>
        {title +
          (keywords
            ? ": " + keywords
            : ": STANDBUY는 누구나 판매할 수 있는 콘텐츠 자유거래 마켓! 한국인이 서로 사고파는 사진과 영상으로 내 콘텐츠의 풍미를 더하다.")}
      </title>
      <meta property="og:title" content={title} />
      <meta property="og:url" content={siteUrl} />
    </Helmet>
  );
}
