import React from "react";
import { motion } from "framer-motion";
import Text from "../../components/design-system/Text";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import { useAxios } from "../../lib/api/useAxios";
import { useQuery } from "@tanstack/react-query";
import { API_DEFAULT } from "../../api/api";
import NoSubsData from "./card/NoSubsData";
import MySubsList from "./card/MySubsList";
import { mySubsList } from "../../api/mySubs/mySubs";
import GoogleAd from "../../components/ads/GoogleAd";
import { UploadTextColor } from "../../styles/color";
import { MetaDatas } from "../../meta/MetaDatas";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import MobileFooter from "../../components/layout/footer/MobileFooter";

export default function MySubsPage() {
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const { data } = useQuery({
    queryKey: ["mySubs"],
    queryFn: mySubsList,
  });

  return (
    <>
      <MetaDatas title={"구독"} siteName={""} siteUrl={location.pathname} />

      <div className={`flex flex-col justify-between h-full ${isMobile && ""}`}>
        <motion.div
          className={`w-full ${isMobile ? "" : "flex justify-center"} mb-10`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <div
            className={`mt-[50px] ${isMobile ? "" : "mx-[200px] max-w-[1280px]"} w-full `}
          >
            <div className="flex items-center mb-[30px] gap-3 mx-5">
              <Text fontSize={isMobile ? 24 : 28} fontWeight={600}>
                구독
              </Text>
            </div>

            <div className="h-[1px] bg-[#afafaf] mb-[30px] mt-[10px] mx-5" />

            {data?.data.length < 1 ? (
              <NoSubsData />
            ) : (
              <MySubsList data={data?.data} />
            )}
          </div>
        </motion.div>
      </div>
    </>
  );
}
