import React from "react";
import { Helmet } from "react-helmet-async";

interface IProps {
  siteName: string;
  title: string;
  siteUrl: string;
  keywords?: any;
  price?: any;
}

export function FeedMetaData({
  title,
  siteUrl,
  keywords,
  price,
}: IProps): JSX.Element {
  const displayPrice = price ? "" : "무료 이미지 |";
  return (
    <Helmet>
      <title>{`${title} | ${keywords} | ${displayPrice} 스탠바이 STANDBUY`}</title>
      <meta property="og:title" content={title} />
      <meta property="og:url" content={siteUrl} />
    </Helmet>
  );
}
