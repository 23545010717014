import React from "react";
import Text from "../../../../components/design-system/Text";

export default function AspectRatioSelector({
  selectedRatio,
  setSelectedRatio,
}: any) {
  // 선택 여부에 따라 border 및 text 색상 반환
  const getStyle = (ratio: string) => {
    return selectedRatio === ratio
      ? "border-white text-white"
      : "border-gray-800 text-gray-500";
  };

  return (
    <div className="bg-black rounded-xl px-10 h-[160px] flex flex-col justify-center gap-4">
      {/* 제목 및 설명 */}
      <div className="flex flex-col items-center gap-4">
        <Text fontSize={14} color="white">
          생성 이미지 비율
        </Text>
      </div>

      {/* 버튼 영역 */}
      <div className="flex gap-4">
        {/* 16:9 버튼 */}
        <div
          onClick={() => setSelectedRatio("16:9")}
          className={`flex justify-center items-center cursor-pointer 
            ${getStyle("16:9")} rounded-lg w-[120px] h-[70px] border-2 
            transition-all duration-300`}
        >
          <span className="text-sm font-semibold">16:9</span>
        </div>

        <div
          onClick={() => setSelectedRatio("16:9")}
          className={`flex justify-center items-center cursor-pointer 
            ${getStyle("16:9")} rounded-lg w-[40px] h-[70px] border-2 
            transition-all duration-300`}
        >
          <span className="text-sm font-semibold">16:9</span>
        </div>

        {/* 4:3 버튼 */}
        <div
          onClick={() => setSelectedRatio("4:3")}
          className={`flex justify-center items-center cursor-pointer 
            ${getStyle("4:3")} rounded-lg w-[120px] h-[70px] border-2 
            transition-all duration-300`}
        >
          <span className="text-sm font-semibold">4:3</span>
        </div>
      </div>
    </div>
  );
}
