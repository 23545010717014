//@ts-noCheck
import styled from "styled-components";
import React, { useCallback, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useAppSelector } from "../../../store/store";
import { API_DEFAULT, GOOGLE_MAP_KEY, URL_HOST } from "../../../api/api";
import LoopItem from "../../../components/common/LoopItem";
import GoogleMap from "../../../lib/modules/google-map";
import Text from "../../../components/design-system/Text";
import { Avatar } from "@material-tailwind/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useAxios } from "../../../lib/api/useAxios";
import { setDetail } from "../../../store/reducer/feedSlice";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { useMediaQuery } from "react-responsive";
import LocationDuplicateModal from "../card/LocationDuplicateModal";

export default function LocationFeedMap({ lat, lng }: any) {
  const { get } = useAxios();
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const bounds = useAppSelector((state) => state.bounds);
  const { positions } = useAppSelector((state) => state.location);
  const [info, setInfo] = useState<any>(null);
  const [infoModal, setInfoModal] = useState<any>({ show: false, data: "" });
  const location = useLocation();
  const [id, setId] = useState<any>(null);
  const [pinData, setPinData] = useState<any>(null);
  const navigate = useNavigate();

  const popupWidth = 1400;
  const popupHeight = 1000;

  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  // 팝업 창을 화면 가운데에 위치시키기 위한 좌표 계산
  const left = (screenWidth - popupWidth) / 2;
  const top = (screenHeight - popupHeight) / 2;

  // 소수점 이하 6자리로 반올림하여 키 생성
  const latLngCountMap = positions?.reduce((acc, pos) => {
    const lat = pos.lat.toFixed(6);
    const lng = pos.lng.toFixed(6);
    const key = `${lat},${lng}`;
    acc[key] = (acc[key] || 0) + 1;
    return acc;
  }, {});

  useEffect(() => {
    setInfo(null);
    setInfoModal({ show: false, data: "" });
    setId(null);
    setPinData(null);

    const _info = positions?.map((item: any) => {
      const lat = item.lat.toFixed(6);
      const lng = item.lng.toFixed(6);
      const key = `${lat},${lng}`;
      const isDuplicate = latLngCountMap[key] >= 2;

      return {
        ...item,
        isDuplicate,
        onClick: () => {
          if (isDuplicate) {
            // 중복된 경우
            setInfoModal({ show: true, data: item });
          } else {
            // 고유한 경우
            setInfoModal({ show: false, data: item });
            window.name = JSON.stringify({ contentId: item.contentId });
            window.open(
              `${URL_HOST}feed-info/${window.btoa(item?.contentId)}`,
              "feed",
              `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`
            );
          }
        },
      };
    });

    setInfo(_info);
  }, [positions, bounds]);

  return (
    <>
      <Content>
        <GoogleMap
          API_KEY={GOOGLE_MAP_KEY as any}
          style={{
            width: "100%",
            height: isMobile ? " calc(100vh - 40vh) " : "calc(100vh)",
          }}
          mapOptions={{
            zoom:
              location.pathname === "/location-feed" && location.state === null
                ? 10
                : location.state?.code
                  ? 7
                  : 11,
            minZoom: 7,
            maxZoom: 20,
            center: {
              lat: lat || 37.5642135,
              lng: lng || 127.0016985,
            },
            zoomControl: true,
            fullscreenControl: true,
            mapTypeControl: true,
            styles: [
              {
                featureType: "poi",
                stylers: [{ visibility: "off" }], // POI 숨기기
              },
              {
                featureType: "transit",
                stylers: [{ visibility: "off" }], // 대중교통 정보 숨기기
              },
            ],
          }}
          positions={info}
          onMarkerClick={(markerData: any) => {
            markerData.onClick();
          }}
        />
      </Content>
      {infoModal.show && (
        <LocationDuplicateModal
          open={infoModal.show}
          onClose={() => setInfoModal({ show: false })}
          listData={infoModal.data}
        />
      )}
    </>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  /* list-wrap */
  .google-map-infowindow {
    width: 360px;
    /* padding: 15px 10px; */
    border-radius: 20px;
    .contentName {
      color: #5b5b5b;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
      letter-spacing: -0.35px;
    }
    .thumbnailPath {
      width: 100%;
      height: 100%;
      margin-bottom: 10px;
      border-radius: 20px;
    }
    .keyword {
      display: inline-block;
      padding: 0 5px;
      color: #989898;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 169.231% */
      letter-spacing: -0.325px;
    }
    .header {
      margin-bottom: 10px;
    }
    .footer {
      display: flex;
      width: 100%;
      height: 39px;
      border-radius: 5px;
      align-items: center;
      justify-content: center;
      background-color: green;

      button {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;
