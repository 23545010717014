import { NovaHeader } from "./card/NovaHeader";
import Text from "../../components/design-system/Text";
import NovaYearPlan from "./card/plan/NovaYearPlan";
import MyNovaPlanCard from "./card/plan/MyNovaPlanCard";
import { useState } from "react";

export default function NovaPlanPage() {
  const [dateType, setDateType] = useState("year");

  return (
    <div className="min-h-screen flex justify-center bg-black pb-10">
      <NovaHeader />

      <div className="flex flex-col items-center gap-10 mt-[100px]">
        <div
          className={`min-w-[800px] flex flex-col items-center gap-12 bg-[#161616] py-10 px-[80px] rounded-xl`}
        >
          <div className="flex flex-col items-center gap-2">
            <Text color="#ffffff" fontSize={28} fontWeight={700}>
              플랜 업그레이드
            </Text>
            <div className="flex gap-4 mt-4">
              <button
                className={`rounded-full ${dateType === "year" ? "bg-black" : "bg-[#ffffff]"} py-1 px-8  opacity-1 cursor-pointer`}
                onClick={() => setDateType("year")}
              >
                <Text
                  color={dateType === "year" ? "#fff" : "#000000"}
                  fontWeight={600}
                >
                  연간
                </Text>
              </button>
              <button
                className={`rounded-full ${dateType === "monthly" ? "bg-black" : "bg-[#ffffff]"} py-1 px-8  opacity-1 cursor-pointer`}
                onClick={() => setDateType("monthly")}
              >
                <Text
                  color={dateType === "monthly" ? "#fff" : "#000000"}
                  fontWeight={600}
                >
                  월간
                </Text>
              </button>
            </div>

            <div className="flex gap-8">
              <NovaYearPlan
                type="Basic"
                price="8,900원"
                date={"월"}
                detail={[
                  "이미지 생성 100개",
                  "고해상도 지원",
                  "AI 스타일 적용 가능",
                ]}
              />
              <NovaYearPlan
                type="Pro"
                price="34,000원"
                date={"월"}
                detail={[
                  "이미지 생성 450개",
                  "상업적 사용가능 라이센스",
                  "프로젝트 2개 동시 생성 가능",
                  "스탠바이 연계 업로드 가능",
                ]}
              />
              <NovaYearPlan
                type="Max"
                price="8,900원"
                date={"월"}
                detail={[
                  "이미지 생성 100개",
                  "고해상도 지원",
                  "AI 스타일 적용 가능",
                ]}
              />
            </div>
          </div>

          <Text
            color="#ffffff"
            fontSize={14}
            fontWeight={700}
            className="cursor-pointer"
          >
            자주 묻는 질문
          </Text>
        </div>
        <MyNovaPlanCard />
      </div>
    </div>
  );
}
