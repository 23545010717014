import React, {
  ChangeEvent,
  useCallback,
  useRef,
  useState,
  useEffect,
} from "react";
import Text from "../../../../components/design-system/Text";
import { analyzeImage } from "../../../../api/nova";

const validTypes = ["image/jpeg", "image/jpg", "image/png"];

export default function ImgDragAndDrop({ files, setFiles }: any) {
  const [isDragging, setIsDragging] = useState(false);
  const dragRef = useRef<HTMLLabelElement | null>(null);
  const fileId = useRef(0);

  const onChangeFiles = useCallback(
    (e: ChangeEvent<HTMLInputElement> | any) => {
      const selectFiles =
        e.type === "drop" ? e.dataTransfer.files : e.target.files;
      const tempFiles: any = [];

      Array.from(selectFiles).forEach((file: any) => {
        if (!validTypes.includes(file.type)) {
          alert(
            `해당 파일은 지원되지 않는 형식입니다.\n지원되는 형식: JPEG, JPG, PNG`
          );
        } else {
          tempFiles.push({
            // id: fileId.current++,
            object: file,
            preview: URL.createObjectURL(file),
          });
        }
      });

      if (tempFiles.length > 0) setFiles(tempFiles);
    },
    []
  );

  // useEffect(() => {
  //   if (files?.length > 0 && files[0]?.object instanceof File) {
  //     analyzeImage(files[0].object.name, files[0]?.object);
  //   }
  // }, [files]);

  // const handleFilterFile = useCallback(
  //   (id: number) => {
  //     setFiles(files.filter((file: any) => file.id !== id));
  //   },
  //   [files]
  // );

  useEffect(() => {
    const handleDrag = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(e.type === "dragover");
    };

    const dropHandler = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onChangeFiles(e);
      setIsDragging(false);
    };

    dragRef.current?.addEventListener("dragover", handleDrag);
    dragRef.current?.addEventListener("dragleave", handleDrag);
    dragRef.current?.addEventListener("drop", dropHandler);

    return () => {
      dragRef.current?.removeEventListener("dragover", handleDrag);
      dragRef.current?.removeEventListener("dragleave", handleDrag);
      dragRef.current?.removeEventListener("drop", dropHandler);
    };
  }, [onChangeFiles]);

  return (
    <div
      className="flex flex-col items-center justify-center bg-black rounded-xl py-4 h-[160px]"
      style={{ width: "fit-content" }}
    >
      <Text fontSize={14} color="white">
        레퍼런스 이미지
      </Text>
      <input
        type="file"
        id="fileUpload"
        className="hidden"
        multiple
        onChange={onChangeFiles}
        accept="image/jpeg, image/jpg, image/png"
      />
      <label
        htmlFor="fileUpload"
        ref={dragRef}
        className={`flex flex-col items-center justify-center w-[180px] h-[100px]  border-black rounded-lg cursor-pointer transition-all duration-150 ${isDragging ? "bg-black text-white" : ""}`}
      >
        {files.length > 0 ? (
          <img
            src={files[0].preview}
            alt="preview"
            className="w-full h-full object-cover rounded-xl"
          />
        ) : (
          <>
            <img
              src={"/img/standbuy/icon/thumbnail-gray.svg"}
              alt="thumbnail-upload"
              width={40}
              className="mb-2"
            />
            <Text fontSize={8} color="gray">
              레퍼런스 이미지 파일을
            </Text>
            <Text fontSize={8} color="gray">
              마우스로 끌어 놓으세요
            </Text>
          </>
        )}
      </label>

      {/* <div className="mt-4 space-y-2">
        {files.map(({ id, object: { name } }) => (
          <div
            key={id}
            className="flex justify-between w-full  border border-black rounded"
          >
            <div>{name}</div>
            <button
              onClick={() => handleFilterFile(id)}
              className="cursor-pointer hover:opacity-70"
            >
              X
            </button>
          </div>
        ))}
      </div> */}
    </div>
  );
}
