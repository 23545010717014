import { useNavigate } from "react-router-dom";
import NovaCreateTool from "./card/NovaCreateTool";
import { NovaHeader } from "./card/NovaHeader";
import Text from "../../components/design-system/Text";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useMutation } from "@tanstack/react-query";
import { gedIdFileDownload, novaPrompt, novaPromptMulti } from "../../api/nova";
import StockText from "../../components/design-system/StockText";
import { useAppDispatch } from "../../store/store";
import { toastFetched } from "../../store/reducer/toastSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaste } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import NovaCreateToolMulti from "./card/NovaCreateToolMulti";
const Wrap = styled.div`
  position: relative;
  background-image: url("/img/standbuy/nova/item-bg.png");
  background-size: cover;
`;

export default function NovaCreateImgMulti() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [text, setText] = useState(""); // 입력된 텍스트 상태
  const [imgList, setImgList] = useState<any>([]); // 배열의 배열로 저장
  const [isLoading, setIsLoading] = useState(false); // 새 블록 추가 감지
  const [selectedRatio, setSelectedRatio] = useState("16:9");
  const [files, setFiles] = useState<[]>([]);
  const [imgCount, setImgCount] = useState(2);
  const [fileUrl, setFileUrl] = useState<any>();
  const [similarityStrength, setSimilarityStrength] = useState<any>(0.5);
  const [cfgScale, setCfgScale] = useState<any>(6.5);
  const [seed, setSeed] = useState<any>(12);

  const { mutate, status } = useMutation({
    mutationFn: novaPromptMulti,
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (data) => {
      console.log(data);
      if (data?.success) {
        setImgList((prev: any) => [
          [
            text,
            data?.data.map((item: any) => ({
              image: item.image,
              genId: item.gen_id, // Store both image and gen_id
            })),
          ],
          ...prev,
        ]);
        setIsLoading(false);
      } else {
        setImgList([]);
        setIsLoading(false);
        dispatch(
          toastFetched({
            show: true,
            text: data?.error?.message,
            type: "error",
          })
        );
      }
    },
  });

  const handleCopyClipBoard = async (text: string) => {
    const setUrl = text;
    try {
      await navigator.clipboard.writeText(setUrl);
      dispatch(
        toastFetched({
          show: true,
          text: "복사 완료",
          type: "success",
        })
      );
    } catch (e) {
      dispatch(
        toastFetched({
          show: true,
          text: "복사 실패",
          type: "error",
        })
      );
    }
  };

  const { mutate: fileDownload } = useMutation({
    mutationFn: (genId: any) => gedIdFileDownload(genId),
    onSuccess: (res) => {
      console.log(res);
      setFileUrl(res);
    },
  });

  const handleDownload = (fileUrl: any) => {
    if (fileUrl !== undefined) {
      const a = document.createElement("a");
      a.href = fileUrl;
      a.download = "downloadedFile.png"; // 필요하다면 실제 파일 이름으로 대체
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  useEffect(() => {
    handleDownload(fileUrl);
  }, [fileUrl]);

  return (
    <div className="flex flex-col items-center ">
      <NovaCreateToolMulti
        //생성 버튼 클릭
        mutate={mutate}
        //프롬프트 입력
        text={text}
        setText={setText}
        //생성 상태
        status={status}
        //해상도 선택
        selectedRatio={selectedRatio}
        setSelectedRatio={setSelectedRatio}
        //이미지 파일 업로드
        files={files}
        setFiles={setFiles}
        //이미지 개수
        imgCount={imgCount}
        setImgCount={setImgCount}
        similarityStrength={similarityStrength}
        setSimilarityStrength={setSimilarityStrength}
        cfgScale={cfgScale}
        setCfgScale={setCfgScale}
        seed={seed}
        setSeed={setSeed}
      />

      {/* 🔥 새로운 블록을 추가하면서, 생성 중이면 스켈레톤 표시 */}
      {isLoading && (
        <Wrap className="w-[1268px] py-12 px-8 mt-10 rounded-2xl">
          <Text fontSize={24} fontWeight={700} color="white" className="ml-6">
            생성 중...
          </Text>

          <div className="flex flex-wrap gap-4 justify-center mt-6">
            {Array.from({ length: imgCount }).map((_, index) => (
              <Skeleton
                key={index}
                width={200}
                height={200}
                className="rounded-lg"
              />
            ))}
          </div>
        </Wrap>
      )}

      {/* 이미지 그룹을 블록 단위로 렌더링 */}
      {imgList?.map((group: any, groupIndex: any) => (
        <div
          key={groupIndex}
          className="w-[1268px] bg-[#161616] py-10 px-8 mt-10 rounded-2xl relative flex gap-20"
        >
          <div className="flex flex-col">
            <div className="flex items-end gap-2">
              <Text
                fontSize={24}
                fontWeight={700}
                color="white"
                className="ml-6"
              >
                생성된 이미지
              </Text>
              <Text
                className="bg-[#ffffff] px-2 rounded-3xl mb-[4px] "
                fontSize={10}
                fontWeight={500}
              >
                Image
              </Text>
            </div>

            <div
              className="mt-2 flex items-end gap-2 max-w-[340px] w-[240px] max-h-[150px] cursor-pointer"
              onClick={() => handleCopyClipBoard(group[0])}
            >
              <StockText
                color={"#CECECE"}
                fontSize={18}
                className=" overflow-hidden break-words text-ellipsis line-clamp-3 "
              >
                {group[0]}
              </StockText>
              <FontAwesomeIcon icon={faPaste} color="white" className="mb-1" />
            </div>
          </div>

          <div className="flex flex-col items-center">
            <div className="flex flex-wrap gap-4 justify-center">
              {group[1].map((item: any) => {
                return (
                  <div
                    className="relative group"
                    key={item.genId}
                    style={{ width: "200px", height: "200px" }}
                  >
                    <img
                      src={item.image}
                      key={item.genId}
                      className="rounded-lg cursor-pointer"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      onClick={() => {
                        // 새 탭에서 이미지 열기
                        const newWindow = window.open(
                          "",
                          "nova-preview",
                          "_blank"
                        );
                        if (newWindow) {
                          // 새 탭에서 HTML 구조를 생성하여 이미지 표시
                          newWindow.document.write(`
                                <html>
                                  <head>
                                    <title>이미지 보기</title>
                                    <style>
                                      body { margin: 0; padding: 0; display: flex; justify-content: center; align-items: center; height: 100vh; background-color: #000; }
                                      img { max-width: 100%; max-height: 100%; object-fit: contain; }
                                      span { 
                                        position: absolute; 
                                        top: 10px; 
                                        right: 10px; 
                                        color: white; 
                                        font-size: 20px; 
                                        cursor: pointer; 
                                        background-color: rgba(0, 0, 0, 0.5); 
                                        padding: 5px; 
                                        border-radius: 50%;
                                      }
                                      span:hover {
                                        background-color: rgba(0, 0, 0, 0.8);
                                      }
                                    </style>
                                  </head>
                                  <body>
                                    <span onClick="window.close()">X</span>
                                    <img src="${item.image}" alt="이미지" />
                                  </body>
                                </html>
            `);
                        }
                      }}
                    />
                    <div className="absolute inset-0 bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity rounded-lg pointer-events-none"></div>
                    <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity flex gap-2 pointer-events-none">
                      <button className="bg-white p-1 rounded pointer-events-auto">
                        Button 1
                      </button>
                      <button
                        className="bg-white p-1 rounded pointer-events-auto"
                        onClick={() => {
                          fileDownload(item.genId);
                        }}
                      >
                        파일 다운로드
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="mt-4 absolute bottom-6 right-10 flex items-center gap-2">
              <button className="bg-[#ffffff] px-4 py-1 rounded-2xl flex items-center gap-1">
                다시 생성하기{" "}
                <img src="/img/standbuy/nova/reload.svg" alt="" width={20} />
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
