import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useQuery } from "@tanstack/react-query";
import { API_DEFAULT, API_SOCIAL, URL_HOST } from "../../api/api";
import { useContext } from "../../util/useContext";
import { FeedNav } from "../../components/design-system/FeedNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "@material-tailwind/react";
import OthersAvatarCard from "./card/OthersAvatarCard";
import OthersProfileCard from "./card/OthersProfileCard";
import OthersStudioFeedCard from "./card/OthersStudioFeedCard";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import OtherStudioMobilePage from "./mobile/OtherStudioMobilePage";
import { instance } from "../../api";
import { useParams } from "react-router";
import { MetaDatas } from "../../meta/MetaDatas";
import { useAppDispatch } from "../../store/store";

export default function OthersStudioPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const { state } = useContext();
  const location = useLocation();
  const [id, setId] = useState<any>();
  const parmas: any = useParams();
  const decodedMemberId = window.atob(parmas?.memberId || "");

  const { data } = useQuery({
    queryKey: ["other-profile"],
    queryFn: async () => {
      const userId = decodedMemberId || state;
      return await instance.get(`${API_DEFAULT}/user/studio/${userId}`);
    },
    enabled: state !== null || parmas?.memberId !== null,
  });

  const { data: checkInfo } = useQuery({
    queryKey: ["subCheck"],
    queryFn: async () => {
      const userId = decodedMemberId || state;
      return await instance.get(
        `${API_SOCIAL}/user/social/get/check/sub/${userId}`
      );
    },
  });

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <MetaDatas
        title={data?.data.data.nickname}
        siteName={""}
        siteUrl={location.pathname}
      />

      {isMobile ? (
        <OtherStudioMobilePage />
      ) : (
        <div className="flex flex-col justify-between h-full">
          <motion.div
            className={`w-full flex justify-center mb-10`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <div className="mt-[120px] mx-[200px] w-full max-w-[1280px]">
              <div className="flex gap-6">
                <OthersAvatarCard
                  data={data?.data.data}
                  check={checkInfo?.data.data.check}
                />
                <OthersProfileCard data={data?.data.data} />
              </div>

              <OthersStudioFeedCard
                data={data?.data.data}
                otherData={state}
                paramsData={decodedMemberId}
              />
            </div>

            <div
              className={`fixed bottom-10 ${isMobile ? "left-5" : "right-10"} z-[9999]`}
            >
              <div className="flex flex-col justify-center items-center gap-4">
                <FeedNav />

                {!isMobile && (
                  <IconButton
                    //@ts-ignore
                    className="rounded-full px-[28px] py-[28px]"
                    placeholder={undefined}
                    children={undefined}
                    size="lg"
                    onClick={moveToTop}
                  >
                    <FontAwesomeIcon icon={faArrowUp} />
                  </IconButton>
                )}
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </>
  );
}
