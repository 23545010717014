//@ts-nocheck
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
} from "@material-tailwind/react";
import Text from "../design-system/Text";
import { ProfileUnderLineGreen, WHITE } from "../../styles/color";
import styled from "styled-components";
import { checkRole } from "../../util";
import { useAxios } from "../../lib/api/useAxios";
import { API_DEFAULT } from "../../api/api";
import { useContext } from "../../util/useContext";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { signIn, signOut } from "../../lib/modules/auth";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
import { addComma } from "../../util/number";
import { shallowEqual } from "react-redux";
import { toastFetched } from "../../store/reducer/toastSlice";
import { signOutLogin } from "../../api/login/login";
import StockText from "../design-system/StockText";

export function ProfileAvatarMenu() {
  const { isLogin, me } = useContext();
  const { accessToken, refreshToken } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const browserData = useAppSelector(
    (state) => state.browser.browser,
    shallowEqual
  );
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: signOutLogin,
    onSuccess: (data) => {
      if (data?.success) {
        signOut({ dispatch: dispatch });
      } else {
        dispatch(
          toastFetched({
            show: true,
            text: data?.error?.message,
            type: "error",
          })
        );
      }
    },
  });

  return (
    <Menu placement="bottom-end">
      <MenuHandler>
        {!me ? (
          <Skeleton width={48} height={48} circle={true} />
        ) : (
          <Avatar
            variant="circular"
            alt="profile img"
            src={
              me?.profileImage
                ? me?.profileImage
                : "/img/standbuy/icon/no-profile.svg"
            }
            placeholder={undefined}
            className="border border-[#134B70] ring-2 hover:ring-2 ring-[#134B70] hover:ring-[#396984] p-0.5"
          />
        )}
      </MenuHandler>

      <MenuList className="min-w-[240px]">
        <MenuItem className="flex items-center gap-2 hover:!bg-white cursor-default">
          <div className="flex items-center gap-4">
            <Avatar
              variant="circular"
              alt="profile img"
              src={
                me?.profileImage
                  ? me?.profileImage
                  : "/img/standbuy/icon/no-profile.svg"
              }
              placeholder={undefined}
              className="border border-[#134B70] ring-2 ring-[#134B70] p-0.5"
            />
            <div className="flex flex-col gap-2">
              <StockText className="subtitle-3  truncate max-w-[140px]">
                {me?.nickname || "-"}
              </StockText>
              <Text
                removeDarkMode
                className="label-2-strong underline underline-offset-1 cursor-pointer "
                color={"#134B70"}
                onClick={() => navigate("/my-page")}
              >
                내 정보 수정
              </Text>
            </div>
          </div>
        </MenuItem>

        <MenuItem className="flex items-center gap-2 hover:!bg-white cursor-default">
          <div className="w-full flex flex-col gap-2 !cursor-default">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => navigate("/fof-credit")}
            >
              <Text removeDarkMode className="label-2 mr-6 ">
                수익
              </Text>
              <Text removeDarkMode className="subtitle-3 ">
                ₩ {addComma(me?.profit) || 0}
              </Text>
            </div>

            <div className="flex items-center">
              <Text removeDarkMode className="label-2 mr-[13px] ">
                구독자
              </Text>
              <Text removeDarkMode className="subtitle-3 ">
                {me?.subscriberCount || 0}
              </Text>
            </div>

            <div className="flex items-center">
              <Text removeDarkMode className="label-2 mr-6 ">
                등급
              </Text>

              <Text removeDarkMode className="subtitle-3 ">
                {checkRole(me?.grade) === "아티스트" ? "아티스트" : "디렉터"}
              </Text>
            </div>
          </div>
        </MenuItem>

        <hr className="my-2 border-blue-gray-50" />

        <MenuItem
          className="flex items-center gap-2 mt-2"
          onClick={() => navigate("/locker")}
        >
          <img src="/img/google-icons/box.svg" alt="box" />

          <Text removeDarkMode className="profile-menu-text ">
            보관함
          </Text>
        </MenuItem>

        <MenuItem
          className="flex items-center gap-2"
          onClick={() => navigate("/my-subs")}
        >
          <img src="/img/google-icons/bookmark.svg" alt="bookmark" />
          <Text removeDarkMode className="profile-menu-text ">
            구독
          </Text>
        </MenuItem>

        <MenuItem
          className="flex items-center gap-2 mb-2"
          onClick={() => navigate("/my-page")}
        >
          <img src="/img/google-icons/mypage-edit.svg" alt="mypage" />
          <Text removeDarkMode className="profile-menu-text ">
            마이페이지
          </Text>
        </MenuItem>

        <MenuItem
          className="flex items-center gap-2 hover:!bg-white"
          onClick={() => navigate(`/my-studio`)}
        >
          <div className="w-full flex justify-center items-center border-[1px] border-gray-300 h-[48px] rounded-md">
            <Text removeDarkMode className="label-1-strong ">
              내 스튜디오
            </Text>
          </div>
        </MenuItem>

        <MenuItem className="flex items-center gap-2 hover:!bg-white">
          <div
            className="w-full flex justify-center items-center"
            onClick={() => {
              if (isLogin) {
                mutate({
                  accessToken: accessToken,
                  refreshToken: refreshToken,
                  browser: browserData,
                });
              }
            }}
          >
            <Text
              removeDarkMode
              className="label-1-strong underline underline-offset-4 "
            >
              로그아웃
            </Text>
          </div>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
