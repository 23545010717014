//@ts-nocheck
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useKeenSlider } from "keen-slider/react";
import Text from "../../components/design-system/Text";
import { Select, Option } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import GlobalCountrySelect from "./card/GlobalCountrySelect";
import MobileFooter from "../../components/layout/footer/MobileFooter";
import CustomFooter from "../../components/layout/footer/CustomFooter";

const animation = { duration: 24000, easing: (t) => t };

const channelCode = [
  { code: "seoul", name: "서울" },
  { code: "incheon", name: "인천" },
  { code: "busan", name: "부산" },
  { code: "daegu", name: "대구" },
  { code: "naver-search", name: "네이버 검색" },
  { code: "google-search", name: "구글 검색" },
  { code: "recommand", name: "지인 추천" },
  { code: "ad", name: "TV광고/지면광고" },
  { code: "etc", name: "기타" },
];

export default function MapPage() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const [channel, setChannel] = useState();
  const [isFocused, setIsFocused] = useState(false);

  const [country, setCountry] = useState();
  const [isCountryFocused, setIsCountryFocused] = useState(false);

  const [sliderRef] = useKeenSlider({
    loop: true,
    renderMode: "performance",
    drag: true,
    slides: {
      perView: "auto",
      spacing: isMobile ? 20 : 80, // 슬라이드 사이 간격
    },
    created(s) {
      s.moveToIdx(5, true, animation);
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation);
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation);
    },
  });

  const handleCountryClick = (country: string) => {
    navigate(`/location-feed/${country?.name}`, {
      state: {
        data: {
          lat: country?.lat,
          lng: country?.lng,
        },
        keyword: country?.name,
        code: country?.code,
      },
    });
  };

  useEffect(() => {
    if (country) {
      // country가 초기값이 아닐 때만 실행
      handleCountryClick(country);
    }
  }, [country]);

  return (
    <div className="flex justify-center">
      <div
        className={`${isMobile ? "mx-3  mt-[40px]" : "mt-[40px] w-[2000px] max-w-[2000px] min-w-[1000px]"} min-h-screen`}
      >
        <div
          className={`keen-slider flex ${isMobile ? "w-[1000px]" : "w-[2000px]"}`}
          ref={sliderRef}
        >
          <div className="keen-slider__slide ">
            <img
              src="/img/standbuy/map/seoul-card.png"
              alt="map"
              width={200}
              className="cursor-pointer"
              onClick={() => {
                navigate(`/location-feed/서울`, {
                  state: {
                    data: {
                      lat: 37.56462664995,
                      lng: 127.02878456871,
                    },
                    keyword: "서울",
                  },
                });
              }}
            />
          </div>
          <div className="keen-slider__slide">
            <img
              src="/img/standbuy/map/tokyo-card.png"
              alt="map"
              width={200}
              className="cursor-pointer"
              onClick={() => {
                navigate(`/location-feed/도쿄`, {
                  state: {
                    data: {
                      lat: 35.65494383172212,
                      lng: 139.74536205548932,
                    },
                    keyword: "도쿄",
                  },
                });
              }}
            />
          </div>
          <div className="keen-slider__slide">
            <img
              src="/img/standbuy/map/london-card.png"
              alt="map"
              width={200}
              className="cursor-pointer"
              onClick={() => {
                navigate(`/location-feed/런던`, {
                  state: {
                    data: {
                      lat: 51.50072919999999,
                      lng: -0.1246254,
                    },
                    keyword: "런던",
                  },
                });
              }}
            />
          </div>
          <div className="keen-slider__slide ">
            <img
              src="/img/standbuy/map/hawai-card.png"
              alt="map"
              width={200}
              className="cursor-pointer"
              onClick={() => {
                navigate(`/location-feed/하와이`, {
                  state: {
                    data: {
                      lat: 21.27098,
                      lng: -157.817391,
                    },
                    keyword: "하와이",
                  },
                });
              }}
            />
          </div>
          <div className="keen-slider__slide">
            <img
              src="/img/standbuy/map/busan-card.png"
              alt="map"
              width={200}
              className="cursor-pointer"
              onClick={() => {
                navigate(`/location-feed/부산`, {
                  state: {
                    data: {
                      lat: 35.157741,
                      lng: 129.059356,
                    },
                    keyword: "부산",
                  },
                });
              }}
            />
          </div>
          <div className="keen-slider__slide">
            <img
              src="/img/standbuy/map/paris-card.png"
              alt="map"
              width={200}
              className="cursor-pointer"
              onClick={() => {
                navigate(`/location-feed/파리`, {
                  state: {
                    data: {
                      lat: 48.857681102802026,
                      lng: 2.2953338014123688,
                    },
                    keyword: "파리",
                  },
                });
              }}
            />
          </div>
        </div>

        <div className="h-[1px] bg-[#afafaf] mb-[30px] mt-[40px] w-full" />

        <div className="flex flex-col gap-2 justify-center items-center mt-[60px]">
          <Text fontSize={38} fontWeight={800}>
            대한민국 지도로 찾기
          </Text>
          <Text>원하는 장소의 콘텐츠를 탐색해보세요.</Text>

          <div className="w-[240px] flex items-center mt-10">
            <Text className="w-full ">지역</Text>
            <Select
              label="알게된 경로"
              onClick={() => {
                setIsFocused(true);
              }}
              value={channel}
              onChange={(val: any) => setChannel(val)}
              children={undefined}
              placeholder={undefined}
              size="lg"
              className="!bg-[#ffffff] border-[#121212]"
              animate={{
                mount: { y: 0 },
                unmount: { y: 25 },
              }}
              labelProps={{
                className: isFocused ? "hidden" : "",
              }}
            >
              {channelCode.map((bank) => (
                <Option key={bank.code} value={bank.code}>
                  {bank.name}
                </Option>
              ))}
            </Select>
          </div>
        </div>

        <div className="h-[1px] bg-[#afafaf] mb-[30px] mt-[40px] w-full" />

        <div className="flex flex-col gap-2 justify-center items-center mt-[60px] mb-[100px]">
          <Text fontSize={38} fontWeight={800}>
            국가 지도로 찾기
          </Text>
          <Text>원하는 국가의 콘텐츠를 탐색해보세요.</Text>

          <div className="w-[240px] flex items-center mt-10 ">
            <Text className="w-full ">국가</Text>
            <GlobalCountrySelect
              country={country}
              setCountry={setCountry}
              isFocused={isCountryFocused}
              setIsFocused={setIsCountryFocused}
            />
          </div>
        </div>

        {isMobile ? <MobileFooter /> : <CustomFooter />}
      </div>
    </div>
  );
}
