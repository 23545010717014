import React from "react";
import Text from "../../../../components/design-system/Text";

export default function MyNovaPlanCard() {
  return (
    <div className="bg-darkBg min-w-[1064px] h-[400px] py-8 px-20 rounded-xl">
      <Text color="white" fontSize={26} fontWeight={700}>
        나의 플랜 관리
      </Text>

      <div className="w-full flex gap-6">
        <div className="w-1/2 py-4 px-6 bg-white rounded-3xl mt-10 h-[240px] flex flex-col justify-between">
          <Text fontSize={40} fontWeight={700}>
            Basic 플랜
          </Text>

          <div className="mb-4">
            <Text>결제일 : 2025.01.01</Text>
            <Text>결제일 : 2025.01.01</Text>
          </div>
        </div>
        <div className="w-1/2 flex flex-col gap-6">
          <div className="w-full h-1/2 py-4 px-6 border-[0.5px] border-[#C5C5C5] rounded-2xl mt-10 relative">
            <Text color="white" fontSize={22} fontWeight={700}>
              남은 생성 이용권
            </Text>

            <Text
              color="white"
              fontSize={22}
              fontWeight={600}
              className="absolute right-8 bottom-1"
            >
              100개
            </Text>
          </div>
          <div className="w-full h-1/2 py-4 px-6 border-[0.5px] border-[#C5C5C5] rounded-2xl  relative">
            <Text color="white" fontSize={22} fontWeight={700}>
              결제내역 확인하기
            </Text>

            <Text
              color="white"
              fontSize={22}
              fontWeight={600}
              className="absolute right-8 bottom-1"
            >
              &gt;
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}
