import DirectorBannerMobile from "../../common/DirectorBannerMobile";
import MobileBoxAvatarCard from "../../common/MobileBoxAvatarCard";
import DirectorBanner from "../../common/DirectorBanner";
import BoxAvatarCard from "../../common/BoxAvatarCard";
import Text from "../../design-system/Text";
import { useMediaQuery } from "react-responsive";
import { useAppSelector, useAppDispatch } from "../../../store/store";
import { useContext } from "../../../util/useContext";
import GoogleAd from "../GoogleAd";
import { UploadTextColor } from "../../../styles/color";
import {
  modalFetched,
  niceModalFetched,
} from "../../../store/reducer/globalModalSlice";
import { useNavigate } from "react-router-dom";

export default function DogBanner() {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const isDesktop = useMediaQuery({ minWidth: 1800 });
  const navigate = useNavigate();
  const browserData = useAppSelector((state) => state.browser.browser);
  const { me, isLogin } = useContext();

  return (
    <div className="my-[50px] mb-[30px]">
      {isMobile ? (
        <div className="flex justify-between gap-6">
          <DirectorBannerMobile />
          <MobileBoxAvatarCard />
        </div>
      ) : (
        <>
          <div className="flex justify-between gap-6">
            <DirectorBanner /> <BoxAvatarCard />
          </div>
        </>
      )}

      {isMobile && (
        <div className={`w-full flex ${!isMobile && "mt-[24px]"} gap-6`}>
          <div className="w-full flex flex-col pl-4 pb-6 justify-center bg-[#F6F6F6] rounded-xl">
            <div className="flex items-center justify-start gap-3 mt-2 ">
              <Text
                color={"#244A6D"}
                fontWeight={700}
                className="text-center "
                fontSize={14}
              >
                광고
              </Text>
              <Text color={"#BEBEBE"} className="text-center " fontSize={11}>
                스탠바이와 무관한 광고 입니다.
              </Text>
            </div>
            <div className="max-w-[240px] flex justify-center mt-4">
              <GoogleAd />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
