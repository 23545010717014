import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import Text from "../../components/design-system/Text";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import { useAxios } from "../../lib/api/useAxios";
import { useQuery } from "@tanstack/react-query";
import { API_DEFAULT } from "../../api/api";
import NoPayList from "./card/NoPayList";
import PayListDetail from "./card/PayListDetail";
import useIntersectObserver from "../../components/hooks/useIntersectObserver";
import SideDrawer from "../../components/design-system/SideDrawer";
import { useMediaQuery } from "react-responsive";
import MobileFooter from "../../components/layout/footer/MobileFooter";
import { instance } from "../../api";
import { useLocation } from "react-router-dom";
import { MetaDatas } from "../../meta/MetaDatas";
import MyPageLayout from "../../components/layout/MyPageLayout";

export default function PayListPAge() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const location = useLocation();

  const getCommentData = async (page: any) => {
    try {
      const res = await instance.get(`${API_DEFAULT}/user/payment/list`, {
        params: {
          page,
          size: 10,
        },
      });
      return res.data.data;
    } catch (error) {
      console.error(error);
    }
  };

  const [page, setPage] = useState(0);
  const [commentList, setCommentList] = useState<any>([]);
  const intersectRef = useRef(null);
  const [isLastPage, setIsLastPage] = useState(false);
  const { isIntersect } = useIntersectObserver(intersectRef, {
    rootMargin: "200px",
    threshold: 0.01,
  });

  const loadMoreCommentData = async () => {
    if (isIntersect) {
      try {
        const data = await getCommentData(page);

        if (data?.length === 0) {
          setIsLastPage(true);
        } else {
          // 여기서 data가 반드시 배열인지 확인
          if (Array.isArray(data)) {
            setCommentList((prevComments: any) => [...prevComments, ...data]);
            setPage((prev) => prev + 1);
          } else {
            // data가 배열이 아닐 때의 처리 로직 추가
            console.error("data is not an array", data);
          }
        }
      } catch (error) {
        console.error("Error loading more comment data:", error);
        // 필요한 경우 에러 상태 설정 또는 사용자에게 피드백 제공
      }
    }
  };
  useEffect(() => {
    loadMoreCommentData();
  }, [isIntersect, isLastPage, page]);

  return (
    <>
      <MetaDatas title={"결제내역"} siteName={""} siteUrl={location.pathname} />

      <div
        className={`flex flex-col justify-between h-full ${isMobile ? "pt-[40px]" : "pt-[80px]"}`}
      >
        <MyPageLayout>
          <div className="pb-[40px] px-6">
            <div className="flex items-center mb-[30px]">
              <Text fontSize={isMobile ? 24 : 28} fontWeight={600}>
                결제 내역
              </Text>
            </div>

            <div className="h-[1px] bg-[#afafaf] mt-[10px]" />

            {commentList?.length < 1 ? (
              <NoPayList />
            ) : (
              <PayListDetail data={commentList} />
            )}

            {!isLastPage && (
              <div id="intersectElement" ref={intersectRef}></div>
            )}
          </div>
        </MyPageLayout>
        {isMobile ? <MobileFooter /> : <CustomFooter />}
      </div>
    </>
  );
}
