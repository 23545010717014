import { motion, AnimatePresence } from "framer-motion";
import SideDrawer from "../../components/design-system/SideDrawer";
import Text from "../../components/design-system/Text";
import { useContext } from "../../util/useContext";
import { addComma } from "../../util/number";
import { useQuery } from "@tanstack/react-query";
import { useMediaQuery } from "react-responsive";
import MobileFooter from "../../components/layout/footer/MobileFooter";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import { settleGet } from "../../api/exchange/exchange";
import { MetaDatas } from "../../meta/MetaDatas";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import FillButton from "../../components/design-system/button/FillButton";
import { useState } from "react";
import BankSelect from "./card/BankSelect";
import BankDetailAdd from "./card/BankDetailAdd";
import { myDetail } from "../../api/info/info";
import BankPayback from "./card/BankPayback";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { niceModalFetched } from "../../store/reducer/globalModalSlice";
import BankSymbol from "./card/BankSymbol";
import MyPageLayout from "../../components/layout/MyPageLayout";
import { shallowEqual } from "react-redux";
export default function NewExchangePage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const location = useLocation();
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);
  const { me, isLogin } = useContext();
  const dispatch = useAppDispatch();
  const [bankSelect, setBankSelect] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenBankDetailAdd, setIsOpenBankDetailAdd] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);

  const { data } = useQuery({
    queryKey: ["settle-stats"],
    queryFn: settleGet,
  });

  const { data: accountInfo } = useQuery({
    queryKey: ["profile-detail"],
    queryFn: myDetail,
  });

  return (
    <>
      <MetaDatas title={"정산"} siteName={""} siteUrl={location.pathname} />
      <div className={`flex flex-col justify-between h-full`}>
        <MyPageLayout>
          <div
            className={`flex items-center mb-[30px] ${isMobile ? "px-6" : ""}`}
          >
            <Text fontSize={isMobile ? 24 : 28} fontWeight={600}>
              정산
            </Text>
          </div>

          <div
            className={`border-[0.5px] border-[#D3D3D3] rounded-2xl min-h-[400px] py-10 ${
              isMobile ? "mx-6" : "px-12"
            }`}
          >
            <Text
              fontWeight={600}
              fontSize={isMobile ? 22 : 24}
              className={isMobile ? "px-6 " : ""}
            >
              계좌정보
            </Text>

            <div className={`flex ${isMobile ? "flex-col px-6" : "gap-6"} `}>
              <div
                className={`${isMobile ? "w-full  h-[200px]" : "w-1/2  h-[280px]"} mt-10 perspective-1000 ${
                  accountInfo?.data?.bankName ? "cursor-pointer" : ""
                }  ${
                  themeData === "light" || themeData === ""
                    ? ""
                    : "border-[#D3D3D3  border-[0.5px] rounded-2xl"
                } `}
                onClick={() => setIsFlipped(!isFlipped)}
              >
                {accountInfo?.data ? (
                  <motion.div
                    className="relative w-full h-full"
                    style={{
                      transformStyle: "preserve-3d",
                    }}
                    animate={{ rotateY: isFlipped ? 180 : 0 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                  >
                    {/* 앞면 */}
                    <motion.div
                      className="absolute w-full h-full bg-white dark:bg-darkBg p-12 rounded-3xl flex justify-center items-center shadow-lg"
                      style={{
                        backfaceVisibility: "hidden",
                      }}
                      whileHover={{
                        scale: 1.05, // 마우스 호버 시 확대
                        boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.3)", // 더 강한 그림자 추가
                      }}
                    >
                      <span
                        className={`${isMobile ? "text-[13px]" : "text-lg"}  font-bold dark:text-darkText`}
                      >
                        클릭하면 계좌정보가 보여요!
                      </span>
                    </motion.div>

                    {/* 뒷면 */}
                    <div
                      className="absolute w-full h-full rounded-3xl shadow-lg p-10 flex flex-col justify-between"
                      style={{
                        backfaceVisibility: "hidden",
                        transform: "rotateY(180deg)",
                      }}
                    >
                      <Text fontSize={28} fontWeight={700}>
                        {me?.nickname} 님
                      </Text>

                      <div className="flex items-center justify-between gap-2">
                        <Text fontSize={isMobile ? 18 : 28} fontWeight={700}>
                          {accountInfo?.data?.bankAccount?.accountNumber ||
                            "계좌등록 안함"}
                        </Text>

                        <BankSymbol
                          accountInfo={accountInfo?.data?.bankAccount?.bankCode}
                        />
                      </div>
                    </div>
                  </motion.div>
                ) : (
                  <div className="w-full h-full flex justify-center items-center">
                    <Text fontSize={24} fontWeight={700}>
                      계좌정보가 없습니다.
                    </Text>
                  </div>
                )}
              </div>

              <div
                className={`${isMobile ? "w-full" : "w-1/2"} flex flex-col gap-6`}
              >
                <div
                  className={`w-full  py-4 px-6 border-[0.5px] border-[#C5C5C5] rounded-2xl mt-10 relative ${
                    isMobile ? "h-[100px]" : "h-1/2"
                  }`}
                >
                  <Text
                    fontSize={isMobile ? 16 : 20}
                    fontWeight={400}
                    className="absolute top-4 left-4 "
                  >
                    정산 가능 금액
                  </Text>

                  <Text
                    fontSize={isMobile ? 16 : 22}
                    fontWeight={600}
                    className="absolute bottom-4 right-4 text-right "
                  >
                    {addComma(data?.data.profit) || 0} ₩
                  </Text>
                </div>
                <div
                  className={`w-full py-4 px-6 border-[0.5px] border-[#C5C5C5] rounded-2xl relative ${
                    isMobile ? "h-[100px]" : "h-1/2"
                  }`}
                >
                  <Text
                    fontSize={isMobile ? 16 : 20}
                    fontWeight={400}
                    className="absolute top-4 left-4 "
                  >
                    총 수익금
                  </Text>

                  <Text
                    fontSize={isMobile ? 16 : 22}
                    fontWeight={600}
                    className="absolute bottom-4 right-4 text-right "
                  >
                    {addComma(data?.data.profit) || 0} ₩
                  </Text>
                </div>
              </div>
            </div>

            <FillButton
              text="정산계좌 등록(변경) 하기"
              onClick={() => {
                if (isLogin && me?.mobileVerification) {
                  setIsOpen(!isOpen);
                } else if (isLogin && !me?.mobileVerification) {
                  dispatch(niceModalFetched({ show: true }));
                }
              }}
              className={`mt-8 rounded-full  py-4 px-4 opacity-1 cursor-pointer ${
                isMobile ? "w-[280px] flex justify-center mx-auto" : "w-full"
              }`}
            />

            <div className="t mt-4">
              <Text
                fontWeight={500}
                fontSize={isMobile ? 12 : 14}
                className={isMobile ? "text-center " : "text-right "}
              >
                *은행 점검시간에는 계좌 등록이 불가할 수 있습니다.
              </Text>
            </div>
          </div>

          {isOpen && !bankSelect && (
            <BankSelect bankSelect={bankSelect} setBankSelect={setBankSelect} />
          )}
          {bankSelect && (
            <BankDetailAdd
              bankSelect={bankSelect}
              setBankSelect={setBankSelect}
              data={accountInfo}
            />
          )}

          <BankPayback data={data} />
        </MyPageLayout>
        {isMobile ? <MobileFooter /> : <CustomFooter />}
      </div>
    </>
  );
}
