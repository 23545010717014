import React from "react";

const banks = [
  ["020", "/img/standbuy/bank/symbol/woori-bank.svg"],
  ["088", "/img/standbuy/bank/symbol/shinhan.svg"],
  ["034", "/img/standbuy/bank/symbol/gwangju.svg"],
  ["011", "/img/standbuy/bank/symbol/nh-bank.svg"],
  ["004", "/img/standbuy/bank/symbol/kb-bank.svg"],
  ["027", "/img/standbuy/bank/symbol/clit-bank.svg"],
  ["081", "/img/standbuy/bank/symbol/hana-bank.svg"],
  ["092", "/img/standbuy/bank/symbol/toss-bank.svg"],
  ["267", "/img/standbuy/bank/symbol/daesin-bank.svg"],
  ["090", "/img/standbuy/bank/symbol/kakao-bank.svg"],
  ["003", "/img/standbuy/bank/symbol/ibk-bank.svg"],
  ["035", "/img/standbuy/bank/symbol/jeju-bank.svg"],
  ["045", "/img/standbuy/bank/symbol/mg-bank.svg"],
  ["007", "/img/standbuy/bank/symbol/suhyup-bank.svg"],
  ["039", "/img/standbuy/bank/symbol/bnk-kn-bank.svg"],
  ["048", "/img/standbuy/bank/symbol/sinhyeob-bank.svg"],
  ["103", "/img/standbuy/bank/symbol/sbi-bank.svg"],
  ["037", "/img/standbuy/bank/symbol/jeonbuk-bank.svg"],
  ["071", "/img/standbuy/bank/symbol/post-bank.svg"],
  ["089", "/img/standbuy/bank/symbol/mk-bank.svg"],
  ["032", "/img/standbuy/bank/symbol/bnk-busan-bank.svg"],
  ["023", "/img/standbuy/bank/symbol/sc-bank.svg"],
  ["002", "/img/standbuy/bank/symbol/kdb-bank.svg"],
  ["etc", "/img/standbuy/bank/symbol/etc-bank.svg"],
];

export default function BankSymbol({ accountInfo }: any) {
  // Find the image path for the given bank code
  const bankImage = banks.find(([code]) => code === accountInfo)?.[1];

  return (
    <div>
      {bankImage && (
        <img src={bankImage} alt="bank-symbol" className="w-10 h-10" />
      )}
    </div>
  );
}
