import React from "react";
import { motion } from "framer-motion";
import Text from "../../components/design-system/Text";
import NoLockerData from "./card/NoLockerData";
import { useQuery } from "@tanstack/react-query";
import LockerList from "./LockerList";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import { UploadTextColor } from "../../styles/color";
import { useMediaQuery } from "react-responsive";
import { lockerList } from "../../api/locker/locker";
import GoogleAd from "../../components/ads/GoogleAd";
import { MetaDatas } from "../../meta/MetaDatas";
import { useLocation } from "react-router-dom";
import MobileFooter from "../../components/layout/footer/MobileFooter";

export default function LockerPage() {
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 820 });

  const { data } = useQuery({
    queryKey: ["locker-get"],
    queryFn: lockerList,
  });

  return (
    <>
      <MetaDatas title={"보관함"} siteName={""} siteUrl={location.pathname} />

      <div className={`flex flex-col justify-between h-full ${isMobile && ""}`}>
        <motion.div
          className={`w-full ${isMobile ? "" : "flex justify-center"} mb-10`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <div
            className={`mt-[50px] ${isMobile ? "" : "mx-[200px] max-w-[1280px]"} w-full `}
          >
            <div className="flex items-center mb-[30px] gap-3 mx-5">
              <Text fontSize={isMobile ? 24 : 28} fontWeight={600}>
                보관함
              </Text>
              <Text
                className=""
                fontSize={isMobile ? 12 : 14}
                fontWeight={500}
                color={UploadTextColor}
              >
                구매한 콘텐츠
              </Text>
            </div>
            <div className="h-[1px] bg-[#afafaf] mb-[30px] mt-[10px] mx-5" />

            {data?.data === undefined ? (
              <NoLockerData />
            ) : (
              <LockerList lockerItem={data?.data} />
            )}
          </div>
        </motion.div>

        {isMobile ? (
          <MobileFooter />
        ) : (
          <CustomFooter styles={"fixed bottom-0"} />
        )}
      </div>
    </>
  );
}
