//@ts-nocheck
import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Text from "../../../../components/design-system/Text";
import FillButton from "../../../../components/design-system/button/FillButton";
import { useMutation } from "@tanstack/react-query";
import { createImageIp, gedIdFileDownload } from "../../../../api/nova";
import { faPaste } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StockText from "../../../../components/design-system/Text";
import styled from "styled-components";
import { LabelInputView } from "../../../../components/design-system/input/LabelInputView";
import { useAppDispatch } from "../../../../store/store";
import { toastFetched } from "../../../../store/reducer/toastSlice";
import PeopleChoiceModal from "../modal/PeopleChoiceModal";

const Wrap = styled.div`
  position: relative;
  background-image: url("/img/standbuy/nova/item-bg.png");
  background-size: cover;
`;

const ButtonWrap = styled.div`
  background: rgba(15, 15, 15, 0.7);

  background-blend-mode: overlay;
`;

const MAX_WIDTH = 1200;
const MAX_HEIGHT = 600;

const CanvasDraw = () => {
  const navigate = useNavigate();
  const backgroundCanvasRef = useRef(null);
  const drawingCanvasRef = useRef(null);
  const ctxRef = useRef(null);
  const dispatch = useAppDispatch();
  //테스트용
  const [quality, setQuality] = useState("standard");
  const [cfgScale, setCfgScale] = useState(6.5);
  const [seed, setSeed] = useState(12);
  const [infoModal, setInfoModal] = useState({ show: false, index: null });

  const [brushOpacity, setBrushOpacity] = useState(0.7); // 🎯 초기 투명도 설정
  const [isLoading, setIsLoading] = useState(false); // 새 블록 추가 감지
  const [imgList, setImgList] = useState<any>([]); // 배열의 배열로 저장
  const [text, setText] = useState("");
  const [isDrawing, setIsDrawing] = useState(false);
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [maskImageFile, setMaskImageFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState<any>();

  const [imageSize, setImageSize] = useState({
    width: MAX_WIDTH,
    height: MAX_HEIGHT,
  });
  const [displaySize, setDisplaySize] = useState({
    width: MAX_WIDTH,
    height: MAX_HEIGHT,
  });
  const [brushSize, setBrushSize] = useState(50);

  // 화면에서 보이는 브러쉬 색
  const brushColor = "#ffffff";
  // 저장 시 적용될 브러쉬 색
  const saveBrushColor = "black";

  // 스트로크 버튼 (최대 4개)
  const [strokeButtons, setStrokeButtons] = useState([]);

  const [strokes, setStrokes] = useState([]); // 그리기 기록 저장
  const [strokeIndex, setStrokeIndex] = useState(-1); // 현재 위치 추적

  // 브러쉬로 한 번 그릴 때마다 버튼 생성 (최대 4개)
  const addStrokeButton = () => {
    setStrokeButtons((prev) => {
      if (prev.length >= 4) return prev;
      return [
        ...prev,
        {
          label: `인물 ${prev.length + 1}`,
          chosenName: "", // 초기값
        },
      ];
    });
  };

  // 모달에서 이름 선택
  const handleSelectName = (name) => {
    setStrokeButtons((prev) =>
      prev.map((btn, idx) =>
        idx === infoModal.index ? { ...btn, chosenName: name } : btn
      )
    );
    setInfoModal({ show: false, index: null });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];

      setImageFile(file); // 파일 객체를 직접 저장
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          let newWidth = img.width;
          let newHeight = img.height;

          if (img.width > MAX_WIDTH || img.height > MAX_HEIGHT) {
            const scale = Math.min(
              MAX_WIDTH / img.width,
              MAX_HEIGHT / img.height
            );
            newWidth = Math.round(img.width * scale);
            newHeight = Math.round(img.height * scale);
          }

          setImageSize({ width: img.width, height: img.height });
          setDisplaySize({ width: newWidth, height: newHeight });
          setImage(e.target.result);
        };
      };
      reader.readAsDataURL(file);
    },
  });

  useEffect(() => {
    if (!image) return;

    const backgroundCanvas = backgroundCanvasRef.current;
    const drawingCanvas = drawingCanvasRef.current;
    if (!backgroundCanvas || !drawingCanvas) return;

    backgroundCanvas.width = drawingCanvas.width = displaySize.width;
    backgroundCanvas.height = drawingCanvas.height = displaySize.height;

    const bgCtx = backgroundCanvas.getContext("2d");
    const img = new Image();
    img.src = image;
    img.onload = () => {
      bgCtx.clearRect(0, 0, backgroundCanvas.width, backgroundCanvas.height);
      bgCtx.drawImage(
        img,
        0,
        0,
        backgroundCanvas.width,
        backgroundCanvas.height
      );

      // 🎯 초기 캔버스 상태 저장 (처음 상태를 복원 가능하게 하기 위함)
      const initialCanvasState = drawingCanvas.toDataURL();
      setStrokes([initialCanvasState]); // 초기 상태 저장
      setStrokeIndex(0);
    };

    const drawCtx = drawingCanvas.getContext("2d");
    drawCtx.lineCap = "round";
    drawCtx.lineJoin = "round";
    drawCtx.strokeStyle = brushColor;
    drawCtx.lineWidth = brushSize;
    ctxRef.current = drawCtx;
  }, [image, displaySize]);

  useEffect(() => {
    if (!ctxRef.current) return;
    ctxRef.current.strokeStyle = brushColor;
    ctxRef.current.lineWidth = brushSize;
  }, [brushSize]);

  const startDrawing = ({ nativeEvent }) => {
    const { offsetX, offsetY } = nativeEvent;
    const ctx = drawingCanvasRef.current.getContext("2d");

    ctx.lineWidth = brushSize;
    ctx.lineCap = "round";
    ctx.strokeStyle = `rgba(255, 255, 255, ${brushOpacity})`;

    ctx.globalCompositeOperation = "xor";

    ctx.beginPath();
    ctx.moveTo(offsetX, offsetY);
    setIsDrawing(true);
  };

  const draw = (e) => {
    if (!isDrawing) return;
    const { offsetX, offsetY } = e.nativeEvent;
    ctxRef.current.lineTo(offsetX, offsetY);
    ctxRef.current.stroke();
  };

  const stopDrawing = () => {
    ctxRef.current.closePath();
    setIsDrawing(false);

    const drawingCanvas = drawingCanvasRef.current;
    if (drawingCanvas) {
      const imageData = drawingCanvas.toDataURL(); // 현재 캔버스 상태 저장
      setStrokes((prevStrokes) => [
        ...prevStrokes.slice(0, strokeIndex + 1),
        imageData,
      ]);
      setStrokeIndex((prevIndex) => prevIndex + 1);
    }

    addStrokeButton();
  };
  // 하나씩 지우기
  const undoLastStroke = () => {
    if (strokeIndex <= 0) return; // 🎯 더 이상 지울 것이 없으면 종료 (맨 처음 상태는 남김)

    const drawingCanvas = drawingCanvasRef.current;
    if (!drawingCanvas) return;

    const drawCtx = drawingCanvas.getContext("2d");
    drawCtx.clearRect(0, 0, drawingCanvas.width, drawingCanvas.height); // 캔버스 초기화

    const newIndex = strokeIndex - 1;
    setStrokeIndex(newIndex); // 🎯 인덱스 감소

    // 🎯 이전 상태로 복원
    const img = new Image();
    img.src = strokes[newIndex];
    img.onload = () => {
      drawCtx.drawImage(img, 0, 0);
    };

    // 🎯 버튼도 함께 삭제
    setStrokeButtons((prev) => prev.slice(0, -1));
  };

  const clearDrawing = () => {
    const drawingCanvas = drawingCanvasRef.current;
    if (!drawingCanvas) return;

    const drawCtx = drawingCanvas.getContext("2d");
    drawCtx.clearRect(0, 0, drawingCanvas.width, drawingCanvas.height); // 캔버스 초기화

    // 🎯 전체 지우기 시, 스토로크 기록도 초기화!
    setStrokes([]); // 💡 모든 기록 삭제
    setStrokeIndex(-1); // 💡 되돌릴 수 없도록 초기화

    // 🎯 스트로크 버튼도 초기화
    setStrokeButtons([]);
  };

  const saveImage = () => {
    const drawingCanvas = drawingCanvasRef.current;
    if (!drawingCanvas) return;

    const newCanvas = document.createElement("canvas");
    newCanvas.width = imageSize.width;
    newCanvas.height = imageSize.height;
    const newCtx = newCanvas.getContext("2d");

    // 🟢 배경을 흰색으로 설정
    newCtx.fillStyle = "white";
    newCtx.fillRect(0, 0, newCanvas.width, newCanvas.height);

    // 🟢 기존 브러쉬 캔버스에서 픽셀 데이터 가져오기
    const drawingCtx = drawingCanvas.getContext("2d");
    const imageData = drawingCtx.getImageData(
      0,
      0,
      drawingCanvas.width,
      drawingCanvas.height
    );
    const data = imageData.data;

    // 🎨 투명한 부분은 흰색, 그린 부분(불투명)은 검은색으로 변경
    for (let i = 0; i < data.length; i += 4) {
      const alpha = data[i + 3]; // Alpha(투명도) 값 가져오기

      if (alpha > 0) {
        // 브러쉬로 그린 부분 -> 검은색 (R=0, G=0, B=0)
        data[i] = 0;
        data[i + 1] = 0;
        data[i + 2] = 0;
        data[i + 3] = 255; // 완전 불투명
      } else {
        // 배경 -> 흰색 (R=255, G=255, B=255)
        data[i] = 255;
        data[i + 1] = 255;
        data[i + 2] = 255;
        data[i + 3] = 255; // 완전 불투명
      }
    }

    // 수정된 픽셀 데이터 다시 적용
    newCtx.putImageData(imageData, 0, 0);

    // 저장
    const link = document.createElement("a");
    link.href = newCanvas.toDataURL("image/png");
    link.download = "drawing.png";
    link.click();
  };

  const getMaskImage = () => {
    const drawingCanvas = drawingCanvasRef.current;
    if (!drawingCanvas) return null;

    const newCanvas = document.createElement("canvas");
    newCanvas.width = imageSize.width;
    newCanvas.height = imageSize.height;
    const newCtx = newCanvas.getContext("2d");

    // 🟢 배경을 흰색으로 설정
    newCtx.fillStyle = "white";
    newCtx.fillRect(0, 0, newCanvas.width, newCanvas.height);

    // 🟢 브러쉬 캔버스를 새로운 캔버스로 변환 후, 검정색 브러쉬 적용
    const brushCanvas = document.createElement("canvas");
    brushCanvas.width = drawingCanvas.width;
    brushCanvas.height = drawingCanvas.height;
    const brushCtx = brushCanvas.getContext("2d");

    brushCtx.drawImage(drawingCanvas, 0, 0);
    brushCtx.globalCompositeOperation = "source-in";
    brushCtx.fillStyle = "black"; // 검정색
    brushCtx.fillRect(0, 0, brushCanvas.width, brushCanvas.height);

    // 변환된 브러쉬 레이어를 최종 캔버스에 추가
    newCtx.drawImage(brushCanvas, 0, 0, newCanvas.width, newCanvas.height);

    return new Promise((resolve) => {
      newCanvas.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], "mask.png", { type: "image/png" });
          setMaskImageFile(file);
          resolve(file);
        } else {
          resolve(null);
        }
      }, "image/png");
    });
  };

  const { mutate, status } = useMutation({
    mutationFn: createImageIp,
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (data) => {
      if (data?.success) {
        if (Array.isArray(data?.data)) {
          setImgList((prev: any) => [
            [
              text,
              data?.data.map((item: any) => ({
                image: item.image,
                genId: item.gen_id, // Store both image and gen_id
              })),
            ],
            ...prev,
          ]);
          setIsLoading(false);
        }
      } else {
        setImgList([]);
        setIsLoading(false);
        dispatch(
          toastFetched({
            show: true,
            text: data?.data.error?.message,
            type: "error",
          })
        );
      }
    },
  });

  const handleGenerateImage = async () => {
    saveImage(); // test
    const maskImageBlob = await getMaskImage();
    setMaskImageFile(maskImageBlob); // 상태에 저장
  };

  const { mutate: fileDownload } = useMutation({
    mutationFn: (genId: any) => gedIdFileDownload(genId),
    onSuccess: (res) => {
      setFileUrl(res);
    },
  });

  const handleDownload = (fileUrl: any) => {
    if (fileUrl !== undefined) {
      const a = document.createElement("a");
      a.href = fileUrl;
      a.download = "downloadedFile.png"; // 필요하다면 실제 파일 이름으로 대체
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  useEffect(() => {
    handleDownload(fileUrl);
  }, [fileUrl]);

  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    // 3초 후 로딩 해제
    setTimeout(() => {
      setLoading(false);
      // 여기서 실제 처리 완료 로직(예: API 호출 결과 처리 등)을 넣으면 됩니다.
    }, 3000);
  };

  return (
    <div style={{ textAlign: "center" }}>
      <div className="relative w-full">
        <div className="absolute -left-[300px] flex items-center gap-2 ml-40">
          <img
            src="/img/standbuy/nova/double-arrow-left.svg"
            alt="nova-tool-bg"
            onClick={() => navigate(-1)}
          />
          <Text
            onClick={() => navigate(-1)}
            className="cursor-pointer "
            color="white"
            fontSize={20}
            fontWeight={700}
          >
            돌아가기
          </Text>
        </div>
      </div>
      {!image ? (
        <div>
          <div
            className="p-10 rounded-2xl w-[900px] h-[360px] flex flex-col items-center justify-center"
            {...getRootProps()}
            style={{
              border: "2px dashed gray",
              padding: 20,
              cursor: "pointer",
            }}
          >
            <input {...getInputProps()} />
            <Text fontSize={36} fontWeight={600} color="white">
              최대 4명의 인물을
            </Text>
            <Text fontSize={36} fontWeight={600} color="white">
              선택한 인물로 변경할 수 있습니다.
            </Text>

            <FillButton
              text="이미지 업로드"
              className="w-[160px] h-[42px] rounded-xl mt-10"
              color="black"
            />

            <div className="mt-10">
              <Text
                fontSize={16}
                fontWeight={600}
                color="rgba(255, 255, 255, 0.5)"
              >
                또는 이미지 파일을
              </Text>
              <Text
                fontSize={16}
                fontWeight={600}
                color="rgba(255, 255, 255, 0.5)"
              >
                마우스로 끌어 놓으세요.
              </Text>
            </div>
          </div>

          <div className="mt-8 flex justify-center gap-6">
            <img
              src="/img/standbuy/nova/canvas-ref-1.png"
              alt=""
              className="w-1/3"
            />
            <img
              src="/img/standbuy/nova/canvas-ref-2.png"
              alt=""
              className="w-1/3"
            />
            <img
              src="/img/standbuy/nova/canvas-ref-3.png"
              alt=""
              className="w-1/3"
            />
          </div>
        </div>
      ) : (
        <>
          <div style={{ display: "inline-block", position: "relative" }}>
            <canvas
              ref={backgroundCanvasRef}
              style={{
                position: "absolute",
                width: displaySize.width,
                height: displaySize.height,
              }}
            />
            <canvas
              ref={drawingCanvasRef}
              onMouseDown={startDrawing}
              onMouseMove={draw}
              onMouseUp={stopDrawing}
              style={{
                border: "1px solid black",
                width: displaySize.width,
                height: displaySize.height,
                position: "relative",
              }}
            />
          </div>
          <div></div>

          <div className="w-[1000px] flex mt-10 justify-between">
            <div className="flex justify-start flex-col items-center ">
              <Text fontSize={24} fontWeight={600} color="white">
                STEP.1 바꾸려는 인물을 브러쉬로 지정해주세요.
              </Text>

              <div className="flex items-center justify-between gap-4 mt-4 w-full">
                <div className="flex items-center justify-center bg-[#0F0F0F] opacity-70 rounded-xl w-1/2 h-[100px]">
                  <div className="flex flex-col items-center justify-center gap-2">
                    <Text fontSize={18} fontWeight={600} color="white">
                      브러쉬
                    </Text>
                    <input
                      type="range"
                      min="1"
                      max="100"
                      value={brushSize}
                      onChange={(e) => setBrushSize(Number(e.target.value))}
                    />
                  </div>
                </div>

                <div className="flex items-center justify-center bg-[#0F0F0F] opacity-70 rounded-xl w-1/2 h-[100px]">
                  {/* <div className="flex flex-col items-center justify-center gap-2">
                    <Text fontSize={18} fontWeight={600} color="white">
                      지우개
                    </Text>
                    <input
                      type="range"
                      min="1"
                      max="100"
                      value={brushSize}
                      onChange={(e) => setBrushSize(Number(e.target.value))}
                    />
                  </div> */}
                  <button
                    onClick={undoLastStroke}
                    className="text-darkText"
                    style={{ marginLeft: 10 }}
                  >
                    순차적으로 지우기
                  </button>
                </div>

                <div>
                  {/* <button
                    onClick={handleGenerateImage}
                    className="text-darkText"
                  >
                    이미지 저장
                  </button> */}
                  {/* <button
                    onClick={clearDrawing}
                    className="text-darkText"
                    style={{ marginLeft: 10 }}
                  >
                    지우기
                  </button>
                  <button
                    onClick={undoLastStroke}
                    className="text-darkText"
                    style={{ marginLeft: 10 }}
                  >
                    순차적으로 지우기
                  </button> */}
                </div>
              </div>
            </div>

            <div className="flex justify-center flex-col items-start gap-4">
              <Text fontSize={24} fontWeight={600} color="white">
                STEP.2 바꿀 인물 지정
              </Text>

              {/* 고정 높이 컨테이너로 변경 */}
              <div className="h-[220px] w-[320px] flex flex-col justify-start gap-1">
                {strokeButtons.length > 0 ? (
                  strokeButtons.map((btn, i) => (
                    <div className="flex gap-1">
                      <ButtonWrap
                        key={i}
                        onClick={() => setInfoModal({ show: true, index: i })}
                        className="text-white bg-gray-700 px-4 py-2 rounded-lg mb-3 flex items-center justify-between w-full"
                      >
                        {btn.label}
                        <Text
                          fontSize={14}
                          color="white"
                          fontWeight={600}
                          className="flex  items-center gap-14 "
                        >
                          {btn.chosenName || "임의지정"}{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="8"
                            viewBox="0 0 9 8"
                            fill="none"
                          >
                            <path
                              opacity="0.99"
                              d="M8.58378 0.1065L4.47728 7.21917L0.37078 0.1065L8.58378 0.1065Z"
                              fill="#D9D9D9"
                            />
                          </svg>
                        </Text>
                      </ButtonWrap>
                    </div>
                  ))
                ) : (
                  <Text fontSize={14} color="gray" className="">
                    {/* 브러쉬로 영역을 지정해주세요 */}
                  </Text>
                )}
              </div>
            </div>
          </div>
          {/* <div className="flex flex-col items-center justify-center gap-4">
            <label className="text-white">
              퀄리티
              <input
                type="text"
                value={quality}
                onChange={(e) => setQuality(e.target.value)}
                placeholder="standard"
                className="text-black ml-4"
              />
            </label>
            <label className="text-white">
              CFG
              <input
                type="number"
                value={cfgScale}
                onChange={(e) => setCfgScale(e.target.value)}
                className="text-black ml-4"
              />
            </label>
            <label className="text-white">
              Seed
              <input
                type="number"
                value={seed}
                onChange={(e) => setSeed(e.target.value)}
                className="text-black ml-4"
              />
            </label>
          </div> */}

          {/* <div>
            <textarea
              disabled={status === "pending"}
              placeholder="이곳에 상황이나 연출하고 싶은 내용을 적어주세요:)"
              className="w-[800px] h-[140px] pt-4 text-white bg-inherit placeholder-white resize-none focus:outline-none"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </div> */}

          {/* <FillButton
            text="생성하기"
            onClick={() => {
              mutate({
                prompt: text,
                imgFile: imageFile,
                maskImage: maskImageFile,
                quality: quality,
                cfgScale: cfgScale,
                seed: seed,
              });
            }}
            className="w-[200px] h-[50px] rounded-xl"
            color="white"
            // disabled={maskImageFile === null}
          /> */}

          <FillButton
            text="생성하기"
            onClick={() => navigate("/nova-ip-end")}
            className="w-[120px] h-[50px] rounded-3xl"
            color="white"
            loading={loading}
            // disabled={maskImageFile === null}
          />

          {/* 🔥 새로운 블록을 추가하면서, 생성 중이면 스켈레톤 표시 */}
          {isLoading && (
            <Wrap className="w-[1268px] py-12 px-8 mt-10 rounded-2xl">
              <Text
                fontSize={24}
                fontWeight={700}
                color="white"
                className="ml-6"
              >
                생성 중...
              </Text>
            </Wrap>
          )}

          {/* 이미지 그룹을 블록 단위로 렌더링 */}
          {imgList?.map((group: any, groupIndex: any) => (
            <div
              key={groupIndex}
              className="w-[1268px] bg-[#161616] py-10 px-8 mt-10 rounded-2xl relative flex gap-20"
            >
              <div className="flex flex-col">
                <div className="flex items-end gap-2">
                  <Text
                    fontSize={24}
                    fontWeight={700}
                    color="white"
                    className="ml-6"
                  >
                    생성된 이미지
                  </Text>
                  <Text
                    className="bg-[#ffffff] px-2 rounded-3xl mb-[4px] "
                    fontSize={10}
                    fontWeight={500}
                  >
                    Image
                  </Text>
                </div>

                <div
                  className="mt-2 flex items-end gap-2 max-w-[340px] w-[240px] max-h-[150px] cursor-pointer"
                  onClick={() => handleCopyClipBoard(group[0])}
                >
                  <StockText
                    color={"#CECECE"}
                    fontSize={18}
                    className=" overflow-hidden break-words text-ellipsis line-clamp-3 "
                  >
                    {group[0]}
                  </StockText>
                  <FontAwesomeIcon
                    icon={faPaste}
                    color="white"
                    className="mb-1"
                  />
                </div>
              </div>

              <div className="flex flex-col items-center">
                <div className="flex flex-wrap gap-4 justify-center">
                  {group[1].map((item: any) => {
                    return (
                      <div
                        className="relative group"
                        key={item.genId}
                        style={{ width: "200px", height: "200px" }}
                      >
                        <img
                          src={item.image}
                          key={item.genId}
                          className="rounded-lg cursor-pointer"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          onClick={() => {
                            // 새 탭에서 이미지 열기
                            const newWindow = window.open(
                              "",
                              "nova-preview",
                              "_blank"
                            );
                            if (newWindow) {
                              // 새 탭에서 HTML 구조를 생성하여 이미지 표시
                              newWindow.document.write(`
                                <html>
                                  <head>
                                    <title>이미지 보기</title>
                                    <style>
                                      body { margin: 0; padding: 0; display: flex; justify-content: center; align-items: center; height: 100vh; background-color: #000; }
                                      img { max-width: 100%; max-height: 100%; object-fit: contain; }
                                      span { 
                                        position: absolute; 
                                        top: 10px; 
                                        right: 10px; 
                                        color: white; 
                                        font-size: 20px; 
                                        cursor: pointer; 
                                        background-color: rgba(0, 0, 0, 0.5); 
                                        padding: 5px; 
                                        border-radius: 50%;
                                      }
                                      span:hover {
                                        background-color: rgba(0, 0, 0, 0.8);
                                      }
                                    </style>
                                  </head>
                                  <body>
                                    <span onClick="window.close()">X</span>
                                    <img src="${item.image}" alt="이미지" />
                                  </body>
                                </html>
            `);
                            }
                          }}
                        />
                        <div className="absolute inset-0 bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity rounded-lg pointer-events-none"></div>
                        <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity flex gap-2 pointer-events-none">
                          <button className="bg-white p-1 rounded pointer-events-auto">
                            Button 1
                          </button>
                          <button
                            className="bg-white p-1 rounded pointer-events-auto"
                            onClick={() => {
                              fileDownload(item.genId);
                            }}
                          >
                            파일 다운로드
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ))}
        </>
      )}
      {infoModal.show && (
        <PeopleChoiceModal
          open={infoModal.show}
          onClose={() => setInfoModal({ show: false })}
          index={infoModal.index}
          handleSelectName={handleSelectName}
        />
      )}
    </div>
  );
};

export default CanvasDraw;
