import React, { useState } from "react";
import Text from "../../../components/design-system/Text";

import FillButton from "../../../components/design-system/button/FillButton";
import { styled } from "styled-components";
import AspectRatioSelector from "./\btool/AspectRatioSelector";
import ImgDragAndDropMulti from "./\btool/ImgDragAndDropMulti";

const Background = styled.div`
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) -26.79%,
    rgba(0, 0, 0, 0.2) -6.82%,
    rgba(22, 22, 22, 0.18) 11.15%,
    rgba(255, 255, 255, 0.04) 123.81%
  );
  background-blend-mode: overlay;
  backdrop-filter: blur(90px);
`;

export default function NovaCreateToolMulti({
  mutate,
  text,
  setText,
  status,
  selectedRatio,
  setSelectedRatio,
  files,
  setFiles,
  imgCount,
  setImgCount,
  similarityStrength,
  setSimilarityStrength,
  cfgScale,
  setCfgScale,
  seed,
  setSeed,
}: any) {
  const maxLength = 1000;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div>
        <div className="flex flex-col items-center justify-center mt-[50px] gap-2">
          {!isOpen && (
            <>
              <img
                src="/img/standbuy/nova/double-arrow.svg"
                alt="nova-tool-bg"
                className="cursor-pointer"
                onClick={() => setIsOpen(!isOpen)}
              />
              <Text
                fontSize={20}
                fontWeight={700}
                color="white"
                className="cursor-pointer "
                onClick={() => setIsOpen(!isOpen)}
              >
                자세한 설정하기
              </Text>
            </>
          )}
        </div>

        <div
          className={`flex justify-between items-center gap-8 py-4 rounded-2xl transition-all duration-700 ease-in-out ${
            isOpen ? "max-h-[500px] opacity-100" : "max-h-0 opacity-0"
          } overflow-hidden`}
        >
          <div
            className={`flex justify-between items-center gap-8 py-8 rounded-2xl transition-all duration-700 ease-in-out ${
              isOpen ? "max-h-[500px] opacity-100" : "max-h-0 opacity-0"
            } overflow-hidden`}
          >
            <ImgDragAndDropMulti files={files} setFiles={setFiles} />
            <div className="flex flex-col gap-2">
              <img
                src="/img/standbuy/nova/double-arrow.svg"
                alt="nova-tool-bg"
                onClick={() => setIsOpen(!isOpen)}
              />
              <Text
                fontSize={20}
                fontWeight={500}
                color="white"
                className="cursor-pointer"
                onClick={() => setIsOpen(!isOpen)}
              >
                접기
              </Text>
            </div>
            <AspectRatioSelector
              selectedRatio={selectedRatio}
              setSelectedRatio={setSelectedRatio}
            />
          </div>
        </div>

        <div className="flex flex-col items-center justify-center gap-4">
          <label className="text-white">
            similarityStrength
            <input
              type="number"
              value={similarityStrength}
              onChange={(e) => setSimilarityStrength(e.target.value)}
              placeholder="0.5"
              className="text-black ml-4"
            />
          </label>
          <label className="text-white">
            CFG
            <input
              type="number"
              value={cfgScale}
              onChange={(e) => setCfgScale(e.target.value)}
              className="text-black ml-4"
            />
          </label>
          <label className="text-white">
            Seed
            <input
              type="number"
              value={seed}
              onChange={(e) => setSeed(e.target.value)}
              className="text-black ml-4"
            />
          </label>
        </div>

        <Background className="flex flex-col items-center rounded-2xl p-4">
          <div className={`w-[1000px] flex flex-col items-center rounded-2xl`}>
            <div className="flex items-center">
              <div>
                <textarea
                  disabled={status === "pending"}
                  placeholder="이곳에 상황이나 연출하고 싶은 내용을 적어주세요:)"
                  className="w-[800px] h-[140px] pt-4 text-white bg-inherit placeholder-white resize-none focus:outline-none"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  maxLength={maxLength}
                />
                <div className="text-left text-white text-sm mt-2">
                  {text.length}/{maxLength}자
                </div>
              </div>

              <div className="">
                <FillButton
                  className="bg-[#fff] h-[40px] w-[120px] py-2 opacity-1 cursor-pointer rounded-xl text-black"
                  onClick={() => {
                    mutate({
                      prompt: text,
                      numberOfImages: imgCount,
                      height: 720,
                      width: 1280,
                      refImg: files,
                      similarityStrength: similarityStrength,
                      cfgScale: cfgScale,
                      seed: seed,
                    });
                  }}
                  disabled={status === "pending"}
                  text={status === "pending" ? "생성 중" : "생성하기"}
                />
              </div>
            </div>
          </div>
        </Background>
      </div>
    </>
  );
}
