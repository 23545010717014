import React from "react";
import Text from "../../../../components/design-system/Text";
import ImgDragAndDrop from "./ImgDragAndDrop";
import { useNavigate } from "react-router-dom";

export default function ImgDragForm({ files, setFiles }: any) {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col gap-4">
        <ImgDragAndDrop files={files} setFiles={setFiles} />
      </div>
    </>
  );
}
