import React from "react";
import Text from "./Text";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
export default function PageCard({
  title,
  textButton,
  children,
  onClick,
}: Props) {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  return (
    <motion.div
      className={`w-full flex justify-center`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <div className="mt-[40px] mx-[200px] w-full max-w-[1280px]">
        <div className="flex justify-between items-center mb-[30px]">
          <Text fontSize={isMobile ? 24 : 28} fontWeight={600}>
            {title}
          </Text>
          <Text
            className="underline underline-offset-4  cursor-pointer"
            fontSize={isMobile ? 14 : 16}
            fontWeight={500}
            onClick={onClick}
          >
            {textButton}
          </Text>
        </div>
        <div className="h-[1px] bg-[#afafaf] mb-[30px]"></div>
        {children}
      </div>
    </motion.div>
  );
}

interface Props {
  title: string;
  textButton?: string;
  children: any;
  onClick?: any;
}
