import React, { useEffect, useRef, useState } from "react";
import { useContext } from "../../../util/useContext";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAxios } from "../../../lib/api/useAxios";
import { getCookie } from "../../../util/cookies";
import { API_DEFAULT } from "../../../api/api";
import { Avatar } from "@material-tailwind/react";
import Text from "../../../components/design-system/Text";
import FillButton from "../../../components/design-system/button/FillButton";
import { LabelInputLength } from "../../../components/design-system/input/LabelInputLength";
import { LabelInputView } from "../../../components/design-system/input/LabelInputView";
import ProfileSetModal from "../../profileEdit/modal/ProfileSetModal";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { useAppDispatch } from "../../../store/store";
import FillIconButton from "../../../components/design-system/button/FillButtonIcon";
import { useNavigate } from "react-router-dom";
import ProfileAvatarModal from "../../profileEdit/modal/ProfileAvatarModal";
import ProfileNickModal from "../../profileEdit/modal/ProfileNickModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";
import { onNicknameChange, onProfileImg } from "../../../api/profile/profile";
import { myDetail } from "../../../api/info/info";
import { checkRole } from "../../../util";
import styled from "styled-components";
import { addComma } from "../../../util/number";
import Dayjs from "dayjs";
import RemoveUserModal from "../../profileEdit/modal/RemoveUserModal";
import StockText from "../../../components/design-system/StockText";
const Wrap = styled.div`
  box-shadow: 4px 4px 12.5px 9px rgba(0, 0, 0, 0.05);
`;

const BackgroundEmail = styled.div`
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #549ecf; // 기존 색상
`;

export default function MyPageEdit() {
  const { me } = useContext();
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { form, get, post } = useAxios();
  const [profileModal, setProfileModal] = useState({ show: false });
  const [profileAvatarModal, setProfileAvatarModal] = useState({ show: false });
  const [profileNickModal, setProfileNickModal] = useState({ show: false });
  const [userRemove, setUserRemove] = useState({ show: false });
  const [loading, setLoading] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [file, setFile] = useState<any>();
  const fileInput = useRef(null);
  const [image, setImage] = useState<any>();
  const [nickname, setNickname] = useState<any>();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { data, status: getStatus } = useQuery({
    queryKey: ["profile-detail"],
    queryFn: myDetail,
  });

  const handleKeyDown = (event: any) => {
    if (event.key === " " || event.key === "Enter") {
      event.preventDefault();
    }
  };

  const onChange = (e: any) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    } else {
      setImage(me?.profileImage || "/img/standbuy/icon/no-profile.svg");
      return;
    }
    //화면에 프로필 사진 표시
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
    setProfileAvatarModal({ show: true });
  };

  useEffect(() => {
    if (me?.profileImage !== null) {
      setImage(me?.profileImage);
    } else {
      setImage("/img/standbuy/icon/no-profile.svg");
    }
  }, [me, file]);

  useEffect(() => {
    if (me?.nickname) {
      setNickname(me?.nickname);
    }

    if (data?.data?.email) {
      setEmail(data?.data?.email);
    }

    if (me?.nickname) {
      setNickname(me?.nickname);
    }
  }, [me, data]);

  const { mutate, status } = useMutation({
    mutationFn: onProfileImg,
    onSuccess: (data) => {
      if (data?.success) {
        setProfileAvatarModal({ show: false });
        queryClient.invalidateQueries({ queryKey: ["info-me"] });
      } else {
        dispatch(
          toastFetched({
            show: true,
            text: data?.error?.message || "알 수 없는 오류가 발생했습니다.",
            type: "error",
          })
        );
        setFile(undefined);
        setProfileAvatarModal({ show: false });
      }
    },
  });

  async function onProfileImgReset() {
    const isConfirmed = window.confirm(
      "지금 프로필 이미지를 변경하면 1주일간 변경할 수 없습니다."
    );

    if (isConfirmed) {
      try {
        const res: any = await onProfileImg(null);

        if (res?.success) {
          alert("프로필 이미지를 초기화하였습니다.");
          window.location.reload();
        } else {
          dispatch(
            toastFetched({
              show: true,
              text: res?.error?.message || "알 수 없는 오류가 발생했습니다.",
              type: "error",
            })
          );
        }
      } catch (error) {
        dispatch(
          toastFetched({
            show: true,
            text: "프로필 이미지 초기화 중 오류가 발생했습니다.",
            type: "error",
          })
        );
      }
    }
  }

  const { mutate: onNickname } = useMutation({
    mutationFn: onNicknameChange,
    onSuccess: (data) => {
      setProfileNickModal({ show: false });
      queryClient.invalidateQueries({ queryKey: ["info-me"] });

      if (data?.success) {
        dispatch(
          toastFetched({
            show: true,
            text: data.data.message,
            type: "success",
          })
        );
      } else {
        dispatch(
          toastFetched({
            show: true,
            text: data?.error.message,
            type: "error",
          })
        );
      }
    },
  });

  useEffect(() => {
    if (status === "pending") {
      setLoading(true);
    } else if (status === "success") {
      setLoading(false);
    }
  }, [status]);

  return (
    <>
      <div
        className={`flex ${isMobile ? "flex-col px-6" : ""} justify-between w-full gap-10 `}
      >
        <div className={`flex flex-col  ${isMobile ? "w-full" : "w-1/2"}`}>
          <div className="flex rounded-t-3xl py-[30px] px-[30px] bg-[#549ECF] justify-between h-full">
            <div className="flex flex-col gap-1">
              <Text fontSize={16} color="white" fontWeight={400}>
                {checkRole(me?.memberGrade) || "Aritst"}
              </Text>

              <StockText
                fontSize={32}
                fontWeight={600}
                color="white"
                className={`dark:text-darkText ${isMobile ? "truncate max-w-[160px]" : ""}`}
              >
                {me?.nickname}
              </StockText>

              <div className="flex gap-10 mt-[30px]">
                <div className="flex flex-col gap-1">
                  <Text
                    fontSize={16}
                    color="rgba(255, 255, 255, 0.7)"
                    fontWeight={400}
                  >
                    가입일
                  </Text>
                  <Text fontSize={16} color="white" fontWeight={600}>
                    {Dayjs(data?.data?.createTime).format("YYYY.MM.DD")}
                  </Text>
                </div>

                <div className="flex flex-col gap-1">
                  <Text
                    fontSize={16}
                    color="rgba(255, 255, 255, 0.7)"
                    fontWeight={400}
                  >
                    구독자 수
                  </Text>
                  <Text fontSize={16} color="white" fontWeight={600}>
                    {addComma(me?.subscriberCount)}
                  </Text>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center gap-4">
              <div
                className="relative"
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
              >
                <Avatar
                  variant="circular"
                  alt="profile img"
                  src={image}
                  placeholder={undefined}
                  className={`${isMobile ? "w-[80px] h-[80px]" : "w-[150px] h-[150px]"} hover:ring-1 ring-[#fff] hover:ring-[#fff] p-0.5 cursor-pointer `}
                />
                <input
                  type="file"
                  style={{ display: "none" }}
                  accept="image/png, image/jpeg, image/svg, image/jpg"
                  name="profile_img"
                  onChange={onChange}
                  ref={fileInput}
                />

                {isHover && (
                  <div
                    className={`${isMobile ? "w-[80px] h-[80px]" : "w-[150px] h-[150px]"} absolute top-0 left-0 rounded-full flex items-center justify-center`}
                    style={{
                      background:
                        "linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%)",
                    }}
                  >
                    <div
                      className={`flex items-center ${isMobile ? "gap-2" : "gap-4"}`}
                    >
                      <FontAwesomeIcon
                        icon={faPencil}
                        className="text-white cursor-pointer"
                        onClick={() => {
                          //@ts-ignore
                          fileInput.current.click();
                        }}
                        style={{ pointerEvents: "auto" }}
                      />
                      <div className="text-white">|</div>
                      <FontAwesomeIcon
                        icon={faTrash} // 추가된 부분
                        className="text-white cursor-pointer" // 아이콘 간의 간격을 위해 ml-2 추가
                        onClick={onProfileImgReset}
                        style={{ pointerEvents: "auto" }}
                      />
                    </div>
                  </div>
                )}
              </div>
              {/* <FillButton
                onClick={() => {
                  setProfileAvatarModal({ show: true });
                }}
                text="적용하기"
                size="lg"
                color="white"
                className="flex justify-center"
                loading={loading}
                disabled={
                  status === "pending" || file === undefined ? true : false
                }
              /> */}
            </div>
          </div>
          <BackgroundEmail className="flex gap-10  pb-[30px] px-[30px] rounded-b-3xl justify-between items-end pt-4">
            <div className="flex flex-col justify-center mt-2 ">
              <Text
                fontSize={16}
                color="rgba(255, 255, 255, 0.7)"
                fontWeight={400}
              >
                이메일
              </Text>
              <Text fontSize={16} color="white" fontWeight={600}>
                {data?.data?.email}
              </Text>
            </div>

            {!isMobile && (
              <img
                src="/img/standbuy/logo/logo-white.svg"
                alt="email"
                width={160}
                height={160}
              />
            )}
          </BackgroundEmail>
        </div>
        <Wrap
          className={`py-8 rounded-3xl flex flex-col gap-10 ${isMobile ? "w-full px-6" : "w-1/2 px-12"}`}
        >
          <div className="flex justify-between items-center">
            <Text fontWeight={700} fontSize={20}>
              내 정보 수정하기
            </Text>
            <Text
              className="cursor-pointer "
              fontWeight={700}
              fontSize={12}
              onClick={() => setUserRemove({ show: true })}
            >
              탈퇴하기
            </Text>
          </div>
          <div className="flex gap-4">
            <LabelInputLength
              width={"100%"}
              label="닉네임"
              value={nickname || ""}
              setValue={setNickname}
              placeholder={"닉네임은 2~15글자의 한글, 영문, 숫자만 가능합니다"}
              maxLength={15}
              onKeyDown={handleKeyDown}
            />

            <FillButton
              onClick={() => {
                setProfileNickModal({ show: true });
              }}
              text="수정"
              size="sm"
              className={
                "cursor-pointer w-[80px] bg-[#B0B0B0] rounded-3xl shadow-inherit"
              }
              loading={loading}
              disabled={status === "pending" ? true : false}
            />
          </div>
          <div className="flex gap-4">
            <LabelInputView
              readOnly={true}
              width={"100%"}
              value={"********************"}
              setValue={() => {}}
              placeholder={"비밀번호"}
              label="비밀번호"
            />

            <FillButton
              onClick={() => navigate("/password-find")}
              text={"변경"}
              className={
                "cursor-pointer w-[80px] bg-[#B0B0B0] rounded-3xl shadow-inherit"
              }
              size="sm"
            />
          </div>

          <div className="flex gap-4">
            <LabelInputView
              readOnly={true}
              width={"100%"}
              value={"********************"}
              setValue={() => {}}
              placeholder={"정산계좌"}
              label="정산계좌"
            />

            <FillButton
              onClick={() => navigate("/exchange")}
              text={"확인"}
              className={
                "cursor-pointer w-[80px] bg-[#B0B0B0] rounded-3xl shadow-inherit"
              }
              size="sm"
            />
          </div>
        </Wrap>
      </div>

      {profileModal.show && (
        <ProfileSetModal
          open={profileModal.show}
          onClose={() => setProfileModal({ show: false })}
        />
      )}

      {profileAvatarModal.show && (
        <ProfileAvatarModal
          open={profileAvatarModal.show}
          onClick={() => mutate(file)}
          onClose={() => setProfileAvatarModal({ show: false })}
        />
      )}

      {profileNickModal.show && (
        <ProfileNickModal
          open={profileNickModal.show}
          onClick={() => onNickname(nickname)}
          onClose={() => setProfileNickModal({ show: false })}
        />
      )}

      {userRemove.show && (
        <RemoveUserModal
          open={userRemove.show}
          onClose={() => setUserRemove({ show: false })}
        />
      )}
    </>
  );
}
