import styled from "styled-components";
import Text from "../../components/design-system/Text";
import UploadMap from "../../components/google/UploadMap";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { LabelInputReset } from "../../components/design-system/input/LabelInputReset";
import {
  getGeocoder,
  getLatLng,
} from "../../lib/modules/google-map/google-map-util";
import { setUploadPayload } from "../../store/reducer/uploadSlice";
import { setGeoPosition } from "../../store/reducer/geoLocationSlice";
import { useLocation, useNavigate } from "react-router-dom";
import useGeolocation from "../../components/hooks/useGeoLocation";
import { LabelInputView } from "../../components/design-system/input/LabelInputView";
import FillButton from "../../components/design-system/button/FillButton";
import { useAxios } from "../../lib/api/useAxios";
import { API_DEFAULT } from "../../api/api";
import UploadCheckModal from "./modal/UploadCheckModal";
import UploadStepper from "../../components/upload/UploadStepper";
import FadeMotionDiv from "../../components/design-system/motion/FadeMotionDiv";
import { useMutation } from "@tanstack/react-query";
import UploadLoading from "./UploadLoading";
import SearchByGoogle from "../../lib/modules/google-map/SearchByGoogle";
import { useContext } from "../../util/useContext";
import { SwitchButton } from "../../components/design-system/SwitchButton";
import { useMediaQuery } from "react-responsive";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import CustomFooterFixed from "../../components/layout/footer/CustomFooterFixed";
import { uploadFlagFetched } from "../../store/reducer/uploadEndSlice";
import MobileFooter from "../../components/layout/footer/MobileFooter";
import { toastFetched } from "../../store/reducer/toastSlice";
import { onUploadEnd } from "../../api/upload/upload";
import { UploadTextColor } from "../../styles/color";
import GeoInfoModal from "./modal/GeoInfoModal";
import { MetaDatas } from "../../meta/MetaDatas";
import GoogleAd from "../../components/ads/GoogleAd";

const BorderWrap = styled.div`
  border-radius: 8px;
  border: 1px solid #e5e5e5;
`;

export default function UploadEnd() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const { state } = useContext();
  const location = useLocation();
  // const locationSet = useGeolocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { form, formProgress } = useAxios();
  const { lat, lng } = useAppSelector((state) => state.geoLocation);
  const { upload_payload } = useAppSelector((state) => state.upload);
  const [formData, setFormData] = useState<any>();
  const [progressData, setProgressData] = useState(0);
  const [switchData, setSwitchData] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [viewGeo, setViewGeo] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputGeo, setInputGeo] = useState("");
  const [geoModal, setGeoModal] = useState({ show: false });

  const [clickPos, setClickPos] = useState<any>({
    lat: lat !== "" ? Number(lat) : Number(state?.lat),
    lng: lng !== "" ? Number(lng) : Number(state?.lon),
  });

  const extractThumbnailPath = (url: any) => {
    try {
      const urlObject = new URL(url);
      const pathname = urlObject.pathname;
      const index = pathname.indexOf("preview/thumbnail/");
      if (index !== -1) {
        return pathname.slice(index);
      }
      return url; // 만약 예상된 부분을 찾지 못하면 원래 URL 반환
    } catch (e) {
      return url;
    }
  };

  // const onSubmit = async () => {
  //   // 주소-좌표 변환 객체를 생성합니다
  //   const res = await getLatLng(keyword);

  //   dispatch(
  //     setGeoPosition({
  //       lat: res?.lat,
  //       lng: res?.lng,
  //     })
  //   );
  // };

  // async function onClickPos() {
  //   const data = {
  //     lat: clickPos?.lat,
  //     lng: clickPos?.lng,
  //   };

  //   const posRes = await getGeocoder(data);

  //   if (posRes) {
  //     setViewGeo(posRes?.address);
  //   }
  // }

  // useEffect(() => {
  //   setTimeout(() => {
  //     onClickPos();
  //   }, 1000);
  // }, [lat, lng, clickPos]);

  useEffect(() => {
    setClickPos({
      lat: lat !== "" ? lat : Number(state?.lat),
      lng: lng !== "" ? lng : Number(state?.lon),
    });
  }, [lat, lng]);

  useEffect(() => {
    setFormData(location.state);
  }, []);

  // onSubmit
  const { mutate } = useMutation({
    mutationFn: onUploadEnd,
    onSuccess: (data) => {
      if (data?.success) {
        dispatch(uploadFlagFetched(data.data.data.message));
        navigate("/upload-sccuess", { state: data.data.data.message });
      } else {
        dispatch(uploadFlagFetched(""));
        alert("업로드에 실패했습니다. 다시 시도해주세요.");
        navigate("/upload");
      }
    },
  });

  useEffect(() => {
    if (clickPos?.lat === 0 || clickPos?.lng === 0) {
      setViewGeo("등록된 주소 정보가 없습니다.");
    }
  }, []);

  const isLatValid = clickPos && clickPos.lat >= -90 && clickPos.lat <= 90;
  const isLngValid = clickPos && clickPos.lng >= -180 && clickPos.lng <= 180;
  const isClickPosValid = isLatValid && isLngValid;

  return (
    <>
      <MetaDatas
        siteName={""}
        title={"업로드 완료"}
        siteUrl={location.pathname}
      />
      <div className={"h-full"}>
        <FadeMotionDiv>
          <div
            className={`flex flex-col justify-start items-start p-12 ${isMobile ? "max-w-[420px]" : "min-w-[1280px] max-w-[1280px]"}`}
          >
            <div className="flex flex-col justify-start items-start w-full">
              <div
                className={`flex gap-4 w-full ${isMobile ? "flex-col" : "mt-[10px]  items-end"}`}
              >
                <Text className={`title ${isMobile ? "mb-2" : "mb-5"}  `}>
                  이제 거의 다 왔어요!
                </Text>

                <Text className={"mb-6 "} fontWeight={500} fontSize={18}>
                  지역 정보를 입력해주세요.
                </Text>
              </div>

              {/*<div className="flex flex-col gap-2 mb-5">*/}
              {/*  <Text*/}
              {/*    fontSize={isMobile ? 12 : 15}*/}
              {/*    fontWeight={600}*/}
              {/*    className="dark:text-[#535353]"*/}
              {/*  >*/}
              {/*    *기존 지원했던 지도검색 기능은 당사의 운영 사정으로 인해*/}
              {/*    잠정적으로 중단되었습니다.*/}
              {/*  </Text>*/}
              {/*</div>*/}

              <div
                className={`w-full flex ${isMobile ? "flex-col gap-2" : "gap-4"} mb-5`}
              >
                <img
                  src={
                    isMobile
                      ? "/img/standbuy/background/upload-banner-mobile-1.svg"
                      : "/img/standbuy/background/upload-banner-1.svg"
                  }
                  alt={"upload-banner"}
                  onClick={() => setGeoModal({ show: true })}
                  className={"cursor-pointer grow"}
                />
                <img
                  src={
                    isMobile
                      ? "/img/standbuy/background/upload-banner-mobile-2.svg"
                      : "/img/standbuy/background/upload-banner-2.svg"
                  }
                  alt={"upload-banner-mobile"}
                  className={"cursor-pointer grow"}
                />
              </div>

              <div className="w-full">
                <UploadMap lat={clickPos?.lat} lng={clickPos?.lng} />
              </div>

              {/*<div className={"mb-4 flex flex-col gap-2"}>*/}
              {/*  <Text*/}
              {/*    fontWeight={600}*/}
              {/*    fontSize={14}*/}
              {/*    color="#0A0607"*/}
              {/*    className={"mt-3"}*/}
              {/*  >*/}
              {/*    지오태깅을 지원하는 몇 기기는 자동으로 핀이 입력 됩니다.*/}
              {/*  </Text>*/}
              {/*</div>*/}

              {/*<BorderWrap*/}
              {/*  className={`p-6 mt-10 ${isMobile ? "w-full" : "w-[880px]"}`}*/}
              {/*>*/}
              {/*  <SearchByGoogle />*/}
              {/*</BorderWrap>*/}

              <BorderWrap className={`p-6 mt-5 w-full`}>
                {/*<LabelInputView*/}
                {/*  readOnly={true}*/}
                {/*  value={viewGeo}*/}
                {/*  setValue={undefined}*/}
                {/*  placeholder={""}*/}
                {/*  label="가장 최근에 표시된 마커 주소의 정보가 저장됩니다."*/}
                {/*  onKeyUp={(event: any) => {*/}
                {/*    if (event.key === "Enter") {*/}
                {/*      !!onSubmit && onSubmit();*/}
                {/*    }*/}
                {/*  }}*/}
                {/*/>*/}
                <LabelInputView
                  disable={clickPos?.lat === 0 || clickPos?.lng === 0}
                  value={inputGeo}
                  setValue={setInputGeo}
                  placeholder={
                    "해당 촬영지에 대해 설명해주세요. ex) 건대입구역 2번출구 앞 노점"
                  }
                  label="장소 설명"
                />
              </BorderWrap>

              <div className="flex flex-col items-center justify-center w-full mt-10">
                <div className="flex flex-col justify-center items-center gap-4 mb-8">
                  <div className="flex items-center gap-3">
                    <SwitchButton
                      value={switchData}
                      onChange={() => setSwitchData(!switchData)}
                    />
                    <Text className="label-1 ">위치를 모르겠어요!</Text>
                  </div>

                  {/* <Text className="label-1" color="#737373">
                  *활성화 하면 콘텐츠 위치 정보에 '위치를 모르겠어요!' 라는
                  문구가 들어갑니다.
                </Text> */}
                </div>

                <FillButton
                  disabled={loading || !isClickPosValid}
                  onClick={() => {
                    setLoading(true);
                    mutate({
                      originFileName: formData.originFileName,
                      address: switchData || !inputGeo ? "" : inputGeo,
                      content_latitude:
                        clickPos?.lat === 0
                          ? ""
                          : clickPos?.lat === "" && switchData === false
                            ? clickPos?.lat
                            : switchData
                              ? ""
                              : clickPos?.lat,
                      content_longitude:
                        clickPos?.lng === 0
                          ? ""
                          : clickPos?.lng === "" && switchData === false
                            ? clickPos?.lng
                            : switchData
                              ? ""
                              : clickPos?.lng,
                      // ownership_price: Number(formData.data.ownershipPrice),
                      ticket_price: Number(formData.data.ticketPrice),
                      content_name: formData.data.content_name,
                      camera_info: formData.data.camera_info,
                      keyword: formData.data.keyword,
                      description: formData.data.description,
                      thumbnail: extractThumbnailPath(formData.data.thumbnail),
                      thumbnail_select: formData.data.thumbnail_select,
                      saveFileName: formData.saveFileName,
                      fileType: formData.fileType,
                      // format_name: formData.detailData.metadata.format_long_name,
                      // format_long_name:
                      //   formData.detailData.metadata.format_long_name,
                      // duration_in_seconds:
                      //   formData.detailData.metadata.duration_in_seconds,
                      // size: formData.detailData.metadata.size,
                      // bit_rate: formData.detailData.metadata.bit_rate,
                      // codec_name: formData.detailData.metadata.codec_name,
                      // width: formData.detailData.metadata.width,
                      // height: formData.detailData.metadata.height,
                      // channels: formData.detailData.metadata.channels,
                      // r_frame_rate: formData.detailData.metadata.r_frame_rate,
                      s: formData.data.s,
                    });
                  }}
                  text={"완료"}
                  className="w-[360px] mb-4 flex justify-center"
                  size="lg"
                  loading={loading}
                />

                {!isClickPosValid && (
                  <Text color={UploadTextColor} className="label-1-strong">
                    위도는 -90에서 90 사이여야 하고, 경도는 -180에서 180
                    사이여야 합니다.
                  </Text>
                )}
              </div>
            </div>
          </div>
        </FadeMotionDiv>
        <Text
          color={UploadTextColor}
          className="px-4 text-center "
          fontSize={15}
        >
          *하단 광고는 STANDBUY와 무관한 광고 입니다.
        </Text>
        <hr className="w-full my-1 border-gray-300 border-[1px]" />

        <div className="w-full sm:max-w-[728px] mx-auto my-5">
          <GoogleAd />
        </div>

        {geoModal.show && (
          <GeoInfoModal
            open={geoModal.show}
            onClose={() => setGeoModal({ show: false })}
          />
        )}

        {isMobile ? <MobileFooter /> : <CustomFooter />}
      </div>
    </>
  );
}
