import { instance } from "../index";
import { API_DEFAULT } from "../api";

export const novaPrompt = async ({
  prompt,
  numberOfImages,
  height,
  width,
  refImg,
  controlMode,
  controlStrength,
  quality,
  cfgScale,
  seed,
}: any) => {
  const url = `${API_DEFAULT}/test/gen/tti?prompt=${prompt}&numberOfImages=${numberOfImages}&height=${height}&width=${width}&controlMode=${controlMode}&controlStrength=${controlStrength}&quality=${quality}&cfgScale=${cfgScale}&seed=${seed}`;

  const _form = new FormData();
  for (const [key, value] of Object.entries({ ref: refImg })) {
    const _key: string = key;
    const _value: any = value;
    _form.append(_key, _value);
  }

  const response = await instance
    .post(url, _form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};

export const novaPromptMulti = async ({
  prompt,
  numberOfImages,
  height,
  width,
  refImg,
  similarityStrength,
  cfgScale,
  seed,
}: any) => {
  const url = `${API_DEFAULT}/test/gen/iv?prompt=${prompt}&numberOfImages=${numberOfImages}&height=${height}&width=${width}&similarityStrength=${similarityStrength}&cfgScale=${cfgScale}&seed=${seed}`;

  const _form = new FormData();

  refImg.forEach((imgFile: File | Blob) => {
    _form.append("refs", imgFile, (imgFile as File).name || "image.png");
  });

  const response = await instance
    .post(url, _form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};

export const analyzeImage = async (title: any, file: any) => {
  const url = `${API_DEFAULT}/test/analyze/image`;

  const _form = new FormData();
  _form.append("fileName", title);
  for (const [key, value] of Object.entries({ file: file })) {
    const _key: string = key;
    const _value: any = value;
    _form.append(_key, _value);
  }
  const response = await instance
    .post(url, _form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data;
    });
  return response;
};

export const gedIdFileDownload = async (genId: any) => {
  const url = `${API_DEFAULT}/test/gen/get?genId=${genId}`;
  const response = await instance
    .post(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};

// 이미지 생성(인페인트)
export const createImageIp = async ({
  prompt,
  imgFile,
  maskImage,
  quality,
  cfgScale,
  seed,
}: any) => {
  const url = `${API_DEFAULT}/test/gen/ip?prompt=${prompt}&numberOfImages=${2}&quality=${quality}&cfgScale=${cfgScale}&seed=${seed}`;

  const _form = new FormData();
  for (const [key, value] of Object.entries({ image: imgFile })) {
    const _key: string = key;
    const _value: any = value;
    _form.append(_key, _value);
  }
  for (const [key, value] of Object.entries({ maskImage: maskImage })) {
    const _key: string = key;
    const _value: any = value;
    _form.append(_key, _value);
  }
  const response = await instance
    .post(url, _form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

//st
export const stPrompt = async ({
  prompt,
  refImg,
  aspectRatio,
  controlStrength,
  seed,
}: any) => {
  const url = `${API_DEFAULT}/test/gen/tti-st?prompt=${prompt}&aspectRatio=${aspectRatio}&controlStrength=${controlStrength}&seed=${seed}`;

  const _form = new FormData();
  for (const [key, value] of Object.entries({ ref: refImg })) {
    const _key: string = key;
    const _value: any = value;
    _form.append(_key, _value);
  }

  const response = await instance
    .post(url, _form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};
