import Text from "../design-system/Text";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import {
  modalFetched,
  niceModalFetched,
} from "../../store/reducer/globalModalSlice";
import { useAppDispatch } from "../../store/store";
import { useContext } from "../../util/useContext";
import { styled } from "styled-components";
import GoogleAd from "../ads/GoogleAd";

const Wrap = styled.div<any>`
  position: relative;
  width: 100%;

  /* &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.4;
    z-index: 1;
    border-radius: 12px;
  } */
`;

export default function DirectorBanner() {
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useAppDispatch();
  const { me, isLogin } = useContext();

  return (
    <div className="w-full flex flex-col">
      <Wrap className="relative">
        <img
          src="/img/standbuy/banner/event-banner.png"
          alt="director-bg"
          className="h-[200px] w-full object-cover cursor-pointer rounded-xl"
          onLoad={() => setIsLoaded(true)}
          onClick={() => {
            navigate("/notice/8");
          }}
        />
        {/* <div className="absolute top-8 left-10 flex items-end gap-8 z-20">
          <Text className="" fontSize={44} color="#FF9B19" fontWeight={900}>
            1년 이용권 → 이용권 변경
          </Text>

          <Text fontSize={20} color={"white"} fontWeight={700} className="mb-2 ">
          1차 신청기간 : 2025.01.27 ~31
        </Text>
        </div> */}

        {/* <div className={"absolute bottom-10 left-10 flex gap-1 z-20 flex-col"}>
          <Text color={"white"} fontWeight={700}>
            1년 이용권이 이용권으로 변경됐어요!
          </Text>
          <Text color={"white"}>이제, 기간에 제약 없이 구매할 수 있어요:)</Text>
        </div> */}
      </Wrap>

      <div className="w-full flex gap-8 mt-6">
        <div className="w-1/2">
          <GoogleAd />
        </div>

        <div className="w-1/2">
          <GoogleAd />
        </div>
      </div>
    </div>
  );
}
