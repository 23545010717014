import React from "react";
import Modal from "../../../../components/design-system/modal/Modal";
import Text from "../../../../components/design-system/Text";
import NovaModal from "../../../../components/design-system/modal/NovaModal";

export default function PeopleChoiceModal({
  open,
  onClose,
  handleSelectName,
  index,
}: any) {
  return (
    <NovaModal
      open={open}
      onClose={onClose}
      headerNone
      width={900}
      height={"550px"}
    >
      <div className="flex items-center gap-2 mb-2">
        <img
          src="/img/standbuy/nova/double-arrow-left.svg"
          alt="nova-tool-bg"
          onClick={() => onClose()}
          className="cursor-pointer"
        />
        <Text
          onClick={() => onClose()}
          className="cursor-pointer "
          color="white"
          fontWeight={700}
        >
          돌아가기
        </Text>
      </div>

      <div className="flex flex-col gap-4">
        <Text fontSize={24} fontWeight={600} color="white">
          인물{index + 1}의 변경 인물을 선택해주세요.
        </Text>

        {/* 버튼들을 가로로 쭉 늘려 배치 */}
        <div className="flex w-full gap-8">
          {/* 예시: 4개의 버튼 */}
          <button
            onClick={() => handleSelectName("윤정")}
            className="relative group flex-1"
          >
            <img
              src="/img/standbuy/nova/people/people-1.png"
              alt="윤정"
              className="rounded-xl w-full"
            />
            <div
              className="
                absolute inset-0
                bg-white/40
                transition duration-300
                ease-in-out
                opacity-100
                group-hover:opacity-0
                rounded-xl
                pointer-events-none
              "
            ></div>
            <Text
              className="absolute bottom-1 left-1/2 transform -translate-x-1/2 z-10"
              fontSize={20}
              fontWeight={600}
              color="white"
            >
              윤정
            </Text>
          </button>

          <button
            onClick={() => handleSelectName("준희")}
            className="relative group flex-1"
          >
            <img
              src="/img/standbuy/nova/people/people-2.png"
              alt="준희"
              className="rounded-xl w-full"
            />
            <div
              className="
                absolute inset-0
                bg-white/40
                transition duration-300
                ease-in-out
                opacity-100
                group-hover:opacity-0
                rounded-xl
                pointer-events-none
              "
            ></div>
            <Text
              className="absolute bottom-1 left-1/2 transform -translate-x-1/2 z-10"
              fontSize={20}
              fontWeight={600}
              color="white"
            >
              준희
            </Text>
          </button>

          <button
            onClick={() => handleSelectName("지용")}
            className="relative group flex-1"
          >
            <img
              src="/img/standbuy/nova/people/people-3.png"
              alt="지용"
              className="rounded-xl w-full"
            />
            <div
              className="
                absolute inset-0
                bg-white/40
                transition duration-300
                ease-in-out
                opacity-100
                group-hover:opacity-0
                rounded-xl
                pointer-events-none
              "
            ></div>
            <Text
              className="absolute bottom-1 left-1/2 transform -translate-x-1/2 z-10"
              fontSize={20}
              fontWeight={600}
              color="white"
            >
              지용
            </Text>
          </button>

          <button
            onClick={() => handleSelectName("현호")}
            className="relative group flex-1"
          >
            <img
              src="/img/standbuy/nova/people/people-4.png"
              alt="현호"
              className="rounded-xl w-full"
            />
            <div
              className="
                absolute inset-0
                bg-white/40
                transition duration-300
                ease-in-out
                opacity-100
                group-hover:opacity-0
                rounded-xl
                pointer-events-none
              "
            ></div>
            <Text
              className="absolute bottom-1 left-1/2 transform -translate-x-1/2 z-10"
              fontSize={20}
              fontWeight={600}
              color="white"
            >
              현호
            </Text>
          </button>
        </div>
      </div>
    </NovaModal>
  );
}
