import React from "react";
import Text from "../../../../components/design-system/Text";
export default function RefImgSearch() {
  return (
    <div className="flex flex-col items-center bg-black rounded-xl py-6 h-[160px] w-[240px]">
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <img src="/img/standbuy/logo/logo-white.svg" width={80} />

          <Text fontSize={14} color="white">
            레퍼런스 찾기
          </Text>
        </div>
      </div>

      <div className="flex px-4 mt-8 gap-2">
        <input
          type="text"
          placeholder="검색어를 입력하세요"
          className="bg-[#D9D9D9] placeholder:text-black text-sm w-full py-1 pl-4 pr-10 rounded-full border border-gray-300  focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        <img src="/img/standbuy/nova/ref-search.svg" width={40} />
      </div>
    </div>
  );
}
