import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import SideDrawer from "../../components/design-system/SideDrawer";
import Text from "../../components/design-system/Text";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import FofCreditList from "./card/FofCreditList";
import CustomTabs from "../../components/design-system/CustomTabs";
import { useMediaQuery } from "react-responsive";
import MobileFooter from "../../components/layout/footer/MobileFooter";
import { useLocation } from "react-router-dom";
import { MetaDatas } from "../../meta/MetaDatas";
import MyPageLayout from "../../components/layout/MyPageLayout";

const setData = [
  {
    label: "판매내역",
    value: "SELL",
  },
  {
    label: "구매내역",
    value: "BUY",
  },
];

export default function FofCreditPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const [value, setValue] = useState(setData[0].value);
  const location = useLocation();

  return (
    <>
      <MetaDatas
        title={"콘텐츠 거래내역"}
        siteName={""}
        siteUrl={location.pathname}
      />

      <div
        className={`flex flex-col justify-between h-full ${isMobile ? "pt-[40px]" : "pt-[80px]"}`}
      >
        <MyPageLayout>
          <div className="pb-[40px] px-6">
            <div className="flex items-center mb-[30px]">
              <Text fontSize={isMobile ? 24 : 28} fontWeight={600}>
                콘텐츠 거래내역
              </Text>
            </div>

            {/*<div className="h-[1px] bg-[#afafaf] mt-[10px]" />*/}

            <CustomTabs
              tabsData={setData}
              value={value}
              setValue={setValue}
              size={isMobile ? "20px" : "28px"}
            />

            {value === "BUY" && <FofCreditList value={value} />}
            {value === "SELL" && <FofCreditList value={value} />}
          </div>
        </MyPageLayout>
        {isMobile ? <MobileFooter /> : <CustomFooter />}
      </div>
    </>
  );
}
