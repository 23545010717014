import { NovaHeader } from "./card/NovaHeader";
import Text from "../../components/design-system/Text";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import NovaCreateTool from "./card/NovaCreateTool";
import NovaCreateImg from "./NovaCreateImg";
import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { gedIdFileDownload, novaPrompt } from "../../api/nova";
import { useAppDispatch } from "../../store/store";
import { toastFetched } from "../../store/reducer/toastSlice";
import Skeleton from "react-loading-skeleton";
import StockText from "../../components/design-system/StockText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaste } from "@fortawesome/free-solid-svg-icons";

const Wrap = styled.div<any>`
  position: relative;
  background-image: url("/img/standbuy/background/nova/background-blob-service.png");
  background-size: cover;
  background-position: center;
`;

const Background = styled.div`
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) -26.79%,
    rgba(0, 0, 0, 0.2) -6.82%,
    rgba(22, 22, 22, 0.18) 11.15%,
    rgba(255, 255, 255, 0.04) 123.81%
  );
  background-blend-mode: overlay;
  backdrop-filter: blur(90px);
`;

export default function NovaPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [imgList, setImgList] = useState<any>([]); // 배열의 배열로 저장
  const [isLoading, setIsLoading] = useState(false); // 새 블록 추가 감지
  const [imgCount, setImgCount] = useState(2);
  const [fileUrl, setFileUrl] = useState<any>();
  const [controlMode, setControlMode] = useState<any>("CANNY_EDGE");
  const [controlStrength, setControlStrength] = useState<any>(0.7);
  const [quality, setQuality] = useState<any>("standard");
  const [cfgScale, setCfgScale] = useState<any>(6.5);
  const [seed, setSeed] = useState<any>(12);
  const [testFlag, setTestFlag] = useState<any>(false);

  const handleBackButtonClick = () => {
    setImgList([]);
  };

  const handleCopyClipBoard = async (text: string) => {
    const setUrl = text;
    try {
      await navigator.clipboard.writeText(setUrl);
      dispatch(
        toastFetched({
          show: true,
          text: "복사 완료",
          type: "success",
        })
      );
    } catch (e) {
      dispatch(
        toastFetched({
          show: true,
          text: "복사 실패",
          type: "error",
        })
      );
    }
  };

  const { mutate: fileDownload } = useMutation({
    mutationFn: (genId: any) => gedIdFileDownload(genId),
    onSuccess: (res) => {
      setFileUrl(res);
    },
  });

  const handleDownload = (fileUrl: any) => {
    if (fileUrl !== undefined) {
      const a = document.createElement("a");
      a.href = fileUrl;
      a.download = "downloadedFile.png"; // 필요하다면 실제 파일 이름으로 대체
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  useEffect(() => {
    handleDownload(fileUrl);
  }, [fileUrl]);

  return (
    <Wrap className="relative min-h-screen flex flex-col items-center bg-black pb-10">
      <NovaHeader />

      {imgList?.length > 0 && (
        <div className="w-full flex justify-start absolute top-28 left-40 mb-20">
          <div className="flex items-center gap-2">
            <img
              src="/img/standbuy/nova/double-arrow-left.svg"
              alt="nova-tool-bg"
              onClick={handleBackButtonClick}
            />
            <Text
              onClick={handleBackButtonClick}
              className="cursor-pointer "
              color="white"
              fontSize={20}
              fontWeight={700}
            >
              뒤로가기
            </Text>
          </div>
        </div>
      )}

      {!testFlag && imgList.length === 0 && (
        <div className="mt-[120px] flex gap-10">
          <div
            className="relative cursor-pointer w-[164px] h-[200px]"
            onClick={() => navigate("/nova-ip")}
          >
            <img
              src="/img/standbuy/nova/face-change.png"
              alt="nova-blob"
              className="rounded-t-[24px]"
            />
            <div className="bg-[#1B1A29] rounded-b-[24px] py-6  flex flex-col gap-1  pl-4">
              <div className="flex flex-col">
                <Text fontSize={16} fontWeight={700} color="white">
                  사진 속 인물
                </Text>
                <Text fontSize={16} fontWeight={700} color="white">
                  얼굴 바꾸기
                </Text>
              </div>

              <div className="flex flex-col gap-1 mt-4">
                <Text fontSize={10} fontWeight={500} color="white">
                  사진 속 인물의 얼굴을 선택한
                </Text>

                <Text fontSize={10} fontWeight={500} color="white">
                  얼굴로 변경.
                </Text>

                <Text fontSize={10} fontWeight={500} color="white">
                  (최대 4명)
                </Text>
              </div>
            </div>

            {/* <div className="absolute inset-0 bg-black bg-opacity-50 rounded-t-[48px] opacity-0 hover:opacity-100 transition-opacity duration-300"></div> */}
          </div>

          <div className="relative cursor-pointer w-[164px] h-[200px]">
            <img
              src="/img/standbuy/nova/item-bg-2.png"
              alt="nova-blob"
              className="rounded-t-[24px]"
            />
            <div className="bg-[#1B1A29] rounded-b-[24px] py-6  flex flex-col gap-1  pl-4">
              <div className="flex flex-col">
                <Text fontSize={16} fontWeight={700} color="white">
                  이미지 생성
                </Text>
                <Text fontSize={16} fontWeight={700} color="white">
                  (텍스트 기반)
                </Text>
              </div>

              <div className="flex flex-col gap-1 mt-4">
                <Text fontSize={10} fontWeight={500} color="white">
                  텍스트 프롬포트를
                </Text>

                <Text fontSize={10} fontWeight={500} color="white">
                  통해 원하는
                </Text>

                <Text fontSize={10} fontWeight={500} color="white">
                  이미지를 생성.
                </Text>
              </div>
            </div>

            {/* <div className="absolute inset-0 bg-black bg-opacity-50 rounded-t-[48px] opacity-0 hover:opacity-100 transition-opacity duration-300"></div> */}
          </div>

          <div className="relative cursor-pointer w-[164px] h-[200px]">
            <img
              src="/img/standbuy/nova/item.png"
              alt="nova-blob"
              className="rounded-t-[24px]"
            />
            <div className="bg-[#1B1A29] rounded-b-[24px] py-6  flex flex-col gap-1  pl-4">
              <div className="flex flex-col">
                <Text fontSize={16} fontWeight={700} color="white">
                  업스케일
                </Text>
                <Text fontSize={16} fontWeight={700} color="white">
                  해상도 높이기
                </Text>
              </div>

              <div className="flex flex-col gap-1 mt-4">
                <Text fontSize={10} fontWeight={500} color="white">
                  낮은 해상도의 저품질 사진을
                </Text>

                <Text fontSize={10} fontWeight={500} color="white">
                  STEPBUY를 통해 고품질
                </Text>

                <Text fontSize={10} fontWeight={500} color="white">
                  이미지로 변화해보세요.
                </Text>
              </div>
            </div>

            {/* <div className="absolute inset-0 bg-black bg-opacity-50 rounded-t-[48px] opacity-0 hover:opacity-100 transition-opacity duration-300"></div> */}
          </div>
        </div>
      )}

      {/* 🔥 새로운 블록을 추가하면서, 생성 중이면 스켈레톤 표시 */}
      {testFlag && (
        <Wrap className="w-[1268px] py-10 px-8 mt-10 rounded-2xl">
          <Text fontSize={24} fontWeight={700} color="white" className="ml-6">
            생성 중...
          </Text>

          <div className="flex flex-wrap gap-4 justify-center mt-6">
            {Array.from({ length: imgCount }).map((_, index) => (
              <Skeleton
                key={index}
                width={200}
                height={200}
                className="rounded-lg"
              />
            ))}
          </div>
        </Wrap>
      )}

      {/* 이미지 그룹을 블록 단위로 렌더링 */}
      {imgList?.map((group: any, groupIndex: any) => (
        <Background
          key={groupIndex}
          className={`w-[1268px] py-10 px-8 mt-10 rounded-2xl relative flex gap-20 ${
            groupIndex === 0 && imgList?.length > 1 ? "mt-40" : ""
          }`}
        >
          <div className="flex flex-col">
            <div className="flex items-end gap-2">
              <Text
                fontSize={24}
                fontWeight={700}
                color="white"
                className="ml-6"
              >
                생성된 이미지
              </Text>
              <Text
                className="bg-[#ffffff] px-2 rounded-3xl mb-[4px] "
                fontSize={10}
                fontWeight={500}
              >
                Image
              </Text>
            </div>

            <div
              className="mt-2 flex items-end gap-2 max-w-[340px] w-[240px] max-h-[150px] cursor-pointer"
              onClick={() => handleCopyClipBoard(group[0])}
            >
              <StockText
                color={"#CECECE"}
                fontSize={18}
                className=" overflow-hidden break-words text-ellipsis line-clamp-3 "
              >
                {group[0]}
              </StockText>
              <FontAwesomeIcon icon={faPaste} color="white" className="mb-1" />
            </div>
          </div>

          <div className="flex flex-col items-center">
            <div className="flex flex-wrap gap-4 justify-center">
              {group[1].map((item: any) => {
                return (
                  <div
                    className="relative group"
                    key={item.genId}
                    style={{ width: "200px", height: "200px" }}
                  >
                    <img
                      src={item.image}
                      key={item.genId}
                      className="rounded-lg cursor-pointer"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      onClick={() => {
                        // 새 탭에서 이미지 열기
                        const newWindow = window.open(
                          "",
                          "nova-preview",
                          "_blank"
                        );
                        if (newWindow) {
                          // 새 탭에서 HTML 구조를 생성하여 이미지 표시
                          newWindow.document.write(`
                                <html>
                                  <head>
                                    <title>이미지 보기</title>
                                    <style>
                                      body { margin: 0; padding: 0; display: flex; justify-content: center; align-items: center; height: 100vh; background-color: #000; }
                                      img { max-width: 100%; max-height: 100%; object-fit: contain; }
                                      span { 
                                        position: absolute; 
                                        top: 10px; 
                                        right: 10px; 
                                        color: white; 
                                        font-size: 20px; 
                                        cursor: pointer; 
                                        background-color: rgba(0, 0, 0, 0.5); 
                                        padding: 5px; 
                                        border-radius: 50%;
                                      }
                                      span:hover {
                                        background-color: rgba(0, 0, 0, 0.8);
                                      }
                                    </style>
                                  </head>
                                  <body>
                                    <span onClick="window.close()">X</span>
                                    <img src="${item.image}" alt="이미지" />
                                  </body>
                                </html>
            `);
                        }
                      }}
                    />
                    <div className="absolute inset-0 bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity rounded-lg pointer-events-none"></div>
                    <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity flex gap-2 pointer-events-none">
                      <button className="bg-white p-1 rounded pointer-events-auto">
                        Button 1
                      </button>
                      <button
                        className="bg-white p-1 rounded pointer-events-auto"
                        onClick={() => {
                          fileDownload(item.genId);
                        }}
                      >
                        파일 다운로드
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="mt-4 absolute bottom-6 right-10 flex items-center gap-2">
              <button className="bg-[#ffffff] px-4 py-1 rounded-2xl flex items-center gap-1">
                다시 생성하기{" "}
                <img src="/img/standbuy/nova/reload.svg" alt="" width={20} />
              </button>
            </div>
          </div>
        </Background>
      ))}
      <NovaCreateImg
        imgList={imgList}
        setImgList={setImgList}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        controlMode={controlMode}
        setControlMode={setControlMode}
        controlStrength={controlStrength}
        setControlStrength={setControlStrength}
        quality={quality}
        setQuality={setQuality}
        cfgScale={cfgScale}
        setCfgScale={setCfgScale}
        seed={seed}
        setSeed={setSeed}
        testFlag={testFlag}
        setTestFlag={setTestFlag}
      />
    </Wrap>
  );
}
