import React, { useRef, useState } from "react";
import Text from "../../../../components/design-system/Text";

const validTypes = ["image/jpeg", "image/jpg", "image/png"];

export default function ImgDragAndDropMulti({ files, setFiles }: any) {
  const [isDragging, setIsDragging] = useState(false);
  const dragRef = useRef<HTMLLabelElement | null>(null);

  function handleFileChange(e: any) {
    setFiles((prevFiles: any) => {
      const newFileArray = Array.from(e.target.files);
      return [...prevFiles, ...newFileArray];
    });
  }

  return (
    <div
      className="flex flex-col items-center justify-center bg-black rounded-xl pt-4 px-4"
      style={{ width: "fit-content" }}
    >
      <Text fontSize={20} color="white">
        레퍼런스 이미지
      </Text>
      <input
        type="file"
        id="fileUpload"
        className="hidden"
        multiple
        onChange={handleFileChange}
        accept="image/jpeg, image/jpg, image/png"
      />
      <label
        htmlFor="fileUpload"
        ref={dragRef}
        className={`flex flex-col items-center justify-center w-[180px] h-40 border-black rounded-lg cursor-pointer transition-all duration-150 ${
          isDragging ? "bg-black text-white" : ""
        }`}
      >
        <img
          src={"/img/standbuy/icon/thumbnail-gray.svg"}
          alt="thumbnail-upload"
          width={40}
          className="mb-2"
        />
        <Text fontSize={12} color="gray">
          레퍼런스 이미지 파일을
        </Text>
        <Text fontSize={12} color="gray">
          마우스로 끌어 놓으세요
        </Text>
      </label>

      <div className="mt-4 flex flex-wrap gap-2">
        {files.map((file: File, index: number) => {
          const imageUrl = URL.createObjectURL(file);
          return (
            <img
              key={index}
              src={imageUrl}
              alt={file.name}
              className="w-20 h-20 object-cover rounded"
            />
          );
        })}
      </div>
    </div>
  );
}
