import Switcher from "../../components/common/Switcher";
import { useLocation, useNavigate } from "react-router-dom";
import JoinCheckForm from "./card/JoinCheckForm";
import { useAppSelector } from "../../store/store";
import { shallowEqual } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { MetaDatas } from "../../meta/MetaDatas";
import { GOOGLE_LOGIN, KAKAO_LOGIN } from "../../api/api";
import Text from "../../components/design-system/Text";
import Card from "../../components/design-system/Card";
export default function JoinPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const navigate = useNavigate();
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);
  const location = useLocation();
  return (
    <>
      <MetaDatas siteName={""} title={"회원가입"} siteUrl={location.pathname} />
      {isMobile ? (
        <div className="bg-white dark:bg-black  flex flex-col justify-center">
          <Switcher className="hidden" />
          <div className={`w-full flex flex-col items-center gap-12 mt-10`}>
            <img
              src={
                themeData === "light" || themeData === ""
                  ? "/img/standbuy/logo/logo.svg"
                  : "/img/standbuy/logo/logo-white.svg"
              }
              alt="standbuy"
              onClick={() => navigate("/")}
              className="cursor-pointer"
              width={200}
            />
            <JoinCheckForm />
          </div>

          <div className="px-12 mt-6 pb-12">
            <Text fontSize={16} fontWeight={600} className="mb-4 ">
              간편가입
            </Text>
            <div
              className="border-black  dark:border-gray-50  border-[0.2px] rounded-md py-2.5 px-7 flex items-center gap-4 cursor-pointer mb-4"
              onClick={() => {
                const url = GOOGLE_LOGIN;
                window.location = url as any;
                localStorage.setItem("LOGIN_TYPE", "google");
              }}
            >
              <img
                src="/img/standbuy/sns/sns_google.png"
                width={30}
                alt="sns_google"
                className="cursor-pointer"
              />
              <Text fontSize={14} fontWeight={700} className="">
                구글 계정으로 시작하기
              </Text>
            </div>

            <div
              className="border-black  dark:border-gray-50  border-[0.2px] rounded-md py-2.5 px-7 flex items-center gap-4 cursor-pointer"
              onClick={() => {
                const url = KAKAO_LOGIN;
                window.location = url as any;
                localStorage.setItem("LOGIN_TYPE", "kakao");
              }}
            >
              <img
                src="/img/standbuy/sns/sns_kakao.png"
                width={30}
                alt="sns_kakao"
                className="cursor-pointer"
              />
              <Text fontSize={14} fontWeight={700} className="">
                카카오톡 계정으로 시작하기
              </Text>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-gray-200 dark:bg-black h-screen flex flex-col gap-3 justify-center items-center">
          <Switcher className="hidden" />
          <div
            className={`w-full flex flex-col items-center gap-12 ${isMobile && "mx-5"}`}
          >
            <img
              src={
                themeData === "light" || themeData === ""
                  ? "/img/standbuy/logo/logo.svg"
                  : "/img/standbuy/logo/logo-white.svg"
              }
              alt="standbuy"
              onClick={() => navigate("/")}
              className="cursor-pointer"
              width={200}
            />
            <JoinCheckForm />
          </div>

          <Card padding="px-12 py-8">
            <Text fontSize={18} fontWeight={600} className="mb-4 ">
              간편가입
            </Text>
            <div
              className="border-black  dark:border-gray-50  border-[0.2px] rounded-md py-2.5 px-7 flex items-center gap-4 cursor-pointer mb-4"
              onClick={() => {
                const url = GOOGLE_LOGIN;
                window.location = url as any;
                localStorage.setItem("LOGIN_TYPE", "google");
              }}
            >
              <img
                src="/img/standbuy/sns/sns_google.png"
                width={30}
                alt="sns_google"
                className="cursor-pointer"
              />
              <Text fontSize={18} fontWeight={700} className="">
                구글 계정으로 시작하기
              </Text>
            </div>

            <div
              className="border-black  dark:border-gray-50  border-[0.2px] rounded-md py-2.5 px-7 flex items-center gap-4 cursor-pointer"
              onClick={() => {
                const url = KAKAO_LOGIN;
                window.location = url as any;
                localStorage.setItem("LOGIN_TYPE", "kakao");
              }}
            >
              <img
                src="/img/standbuy/sns/sns_kakao.png"
                width={30}
                alt="sns_kakao"
                className="cursor-pointer"
              />
              <Text fontSize={18} fontWeight={700} className="">
                카카오톡 계정으로 시작하기
              </Text>
            </div>
          </Card>
        </div>
      )}
    </>
  );
}
