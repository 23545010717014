import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import Text from "../../../components/design-system/Text";
import { useQuery } from "@tanstack/react-query";
import { settleGet } from "../../../api/exchange/exchange";
import { addComma } from "../../../util/number";
import FillButton from "../../../components/design-system/button/FillButton";
import { useContext } from "../../../util/useContext";
import ExChangeModal from "../modal/ExChangeModal";

export default function BankPayback({ data }: any) {
  const { me } = useContext();
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const [checkModal, setCheckModal] = useState<any>({
    show: false,
    data: "",
  });

  const handleValueCheck = () => {
    setCheckModal({ show: true });
  };

  return (
    <div
      className={`my-[40px] ${isMobile ? "px-6" : "max-w-[1280px]"} w-full `}
    >
      <div className="flex items-center mb-[30px]">
        <Text fontSize={isMobile ? 24 : 28} fontWeight={600}>
          정산 요청하기
        </Text>
      </div>

      <div
        className={`border-[0.5px] border-[#D3D3D3] rounded-2xl min-h-[400px] py-10 px-12 mt-8 ${
          isMobile ? "px-6" : "px-12"
        }`}
      >
        <Text fontWeight={600} fontSize={isMobile ? 22 : 24}>
          수익 및 정산금
        </Text>

        <div
          className={`flex  w-full mt-4  ${
            isMobile ? "flex-col gap-4" : "justify-center items-center gap-10"
          }`}
        >
          <div
            className={`pt-4 pb-4 px-6 border-[0.5px] border-[#C5C5C5] rounded-2xl flex justify-center flex-col items-center gap-4 ${
              isMobile ? "w-full" : "w-1/2 "
            }`}
          >
            <Text fontSize={isMobile ? 16 : 18} fontWeight={400}>
              총 수익금
            </Text>

            <Text
              fontSize={isMobile ? 16 : 18}
              fontWeight={500}
              color={"#C0C0C0"}
              className="flex justify-center "
            >
              {addComma(data?.data.profit) || 0} ₩
            </Text>
          </div>

          <div
            className={`pt-4 pb-4 px-6 border-[2px] border-[#134B70] rounded-2xl flex justify-center flex-col items-center gap-4 ${
              isMobile ? "w-full" : "w-1/2 "
            }`}
          >
            <Text fontSize={isMobile ? 16 : 18} fontWeight={400}>
              확정 수익금
            </Text>

            <Text fontSize={isMobile ? 16 : 18} fontWeight={500}>
              {addComma(data?.data.profit) || 0} ₩
            </Text>
          </div>
        </div>

        <Text fontSize={isMobile ? 16 : 20} fontWeight={400} className="mt-10 ">
          금액
        </Text>

        <div className="flex items-center border-b border-gray-300 py-2">
          <input
            type="text"
            className="placeholder:text-black appearance-none bg-transparent border-none w-full text-black font-semibold mr-3 py-1 leading-tight focus:outline-none placeholder:dark:text-darkText"
            placeholder={`${addComma(data?.data.profit) || 0} (5,000원 부터 정산 요청 가능해요)`}
            readOnly={true}
          />
          <span className="text-black font-semibold dark:text-darkText">₩</span>
        </div>

        <FillButton
          text="정산 요청"
          onClick={handleValueCheck}
          disabled={data?.data.profit < 4999}
          className="mt-8 rounded-full w-full py-4 px-4 opacity-1 cursor-pointer "
        />

        <ul
          style={{
            listStyle: "inside",
            color: "#A7A7A7",
            fontSize: "14px",
            fontWeight: "500",
            marginTop: "20px",
          }}
        >
          <li>수익금에 대하여 현금으로 정산받을 수 있어요.</li>
          <li>
            정산 수수료(아티스트 : 33%, 디렉터 22%)를 제외하고 등록하신 계좌로
            정산해요.
          </li>
          <li>전체 수익 중, 구매가 확정된 금액까지 정산 받을 수 있어요</li>
          <li>신청된 정산 신청은 최대 영업일 기준 5일 소요 됩니다.</li>
        </ul>
      </div>

      {checkModal.show && (
        <ExChangeModal
          open={checkModal.show}
          onClose={() => setCheckModal({ show: false })}
          fof={data?.possible}
          me={me}
        />
      )}
    </div>
  );
}
