//@ts-nocheck
import React from "react";
import { Navbar } from "@material-tailwind/react";
import { useMediaQuery } from "react-responsive";
import { useContext } from "../../../util/useContext";
import { useLocation, useNavigate } from "react-router-dom";
import Text from "../../../components/design-system/Text";

export function NovaHeader() {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const { isLogin } = useContext();

  return (
    <div className={`max-h-[120px] w-full fixed top-0 z-[999]`}>
      <Navbar className="!shadow-none h-max max-w-full rounded-none px-4 py-[15px] lg:px-12 border border-t-0 border-l-0 border-r-0 border-b-1 border-slate-50 bg-black dark:border-transparent border-[#1a1a1a]">
        <div className="flex items-center justify-between text-blue-gray-900">
          <div className="flex">
            <img src={"/img/standbuy/logo/fof.svg"} alt="스탠바이" width={60} />
          </div>

          <div className="flex gap-12 items-center pl-[240px]">
            <Text
              onClick={() => navigate("/nova")}
              color={location.pathname === "/nova" ? "" : "text-[#777777]"}
              fontWeight={600}
              className={`${location.pathname === "/nova" ? "rounded-full bg-[#ffffff] py-1 px-4 text-['#fff'] " : "text-[#777777] cursor-pointer "}`}
            >
              Home
            </Text>

            <Text
              onClick={() => navigate("/nova-test")}
              color={
                location.pathname === "/nova-test " ? "" : "text-[#777777]"
              }
              fontWeight={600}
              className={`${location.pathname === "/nova-test" ? "rounded-full bg-[#ffffff] py-1 px-4 text-['#fff'] " : "text-[#777777] cursor-pointer "}`}
            >
              Generate
            </Text>
            <Text
              onClick={() => navigate("/nova/plan")}
              color={location.pathname === "/nova/plan" ? "" : "text-[#777777]"}
              fontWeight={600}
              className={`${location.pathname === "/nova/plan" ? "rounded-full bg-[#ffffff] py-1 px-4 text-['#fff'] " : "text-[#777777] cursor-pointer "}`}
            >
              plan
            </Text>
          </div>

          <div className="flex items-center gap-10">
            <img
              src={"/img/standbuy/logo/logo-white.svg"}
              alt="스탠바이"
              width={90}
              className="cursor-pointer mt-1"
              onClick={() => navigate("/")}
            />

            <div onClick={() => navigate("/login")}>
              <Text color="white" fontWeight={600} className="cursor-pointer ">
                Log In
              </Text>
            </div>

            <button
              className="rounded-full bg-[#ffffff] py-1 px-4 opacity-1 cursor-pointer "
              onClick={() => navigate(isLogin ? "/feed" : "/login")}
            >
              <Text color="#000000" fontWeight={600}>
                Sign Up
              </Text>
            </button>
          </div>
        </div>
      </Navbar>
    </div>
  );
}
