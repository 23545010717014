import { useState } from "react";
import Text from "../../../components/design-system/Text";
import BankAddModal from "../../myPage/modal/BankAddModal";
import { useMediaQuery } from "react-responsive";

const banks = [
  ["020", "/img/standbuy/bank/woori-bank.svg"],
  ["088", "/img/standbuy/bank/shinhan.svg"],
  ["034", "/img/standbuy/bank/gwangju.svg"],
  ["011", "/img/standbuy/bank/nh-bank.svg"],
  ["004", "/img/standbuy/bank/kb-bank.svg"],
  ["027", "/img/standbuy/bank/clit-bank.svg"],
  ["081", "/img/standbuy/bank/hana-bank.svg"],
  ["092", "/img/standbuy/bank/toss-bank.svg"],
  ["267", "/img/standbuy/bank/daesin-bank.svg"],
  ["090", "/img/standbuy/bank/kakao-bank.svg"],
  ["003", "/img/standbuy/bank/ibk-bank.svg"],
  ["035", "/img/standbuy/bank/jeju-bank.svg"],
  ["045", "/img/standbuy/bank/mg-bank.svg"],
  ["007", "/img/standbuy/bank/suhyup-bank.svg"],
  ["039", "/img/standbuy/bank/bnk-kn-bank.svg"],
  ["048", "/img/standbuy/bank/sinhyeob-bank.svg"],
  ["103", "/img/standbuy/bank/sbi-bank.svg"],
  ["037", "/img/standbuy/bank/jeonbuk-bank.svg"],
  ["071", "/img/standbuy/bank/post-bank.svg"],
  ["089", "/img/standbuy/bank/mk-bank.svg"],
  ["032", "/img/standbuy/bank/bnk-busan-bank.svg"],
  ["023", "/img/standbuy/bank/sc-bank.svg"],
  ["002", "/img/standbuy/bank/kdb-bank.svg"],
  ["etc", "/img/standbuy/bank/etc-bank.svg"],
];

export default function BankSelect({ bankSelect, setBankSelect }: any) {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const [addModal, setAddModal] = useState({ show: false });

  return (
    <>
      <div
        className={`border-[0.5px] border-[#D3D3D3] rounded-2xl min-h-[400px] py-10 ${
          isMobile ? "px-6" : "px-12"
        } mt-8`}
      >
        <Text fontSize={isMobile ? 22 : 24} fontWeight={600}>
          계좌등록
        </Text>

        <div className="mt-10">
          <Text fontSize={18} fontWeight={500}>
            1. 은행을 선택해주세요.
          </Text>
          <div
            className={`grid  gap-3 mt-4 ${
              isMobile ? "grid-cols-3" : "grid-cols-4"
            }`}
          >
            {banks.map((bank) => (
              <div
                className="cursor-pointer"
                key={bank[0]}
                onClick={() => {
                  if (bank[0] === "etc") {
                    setAddModal({ show: true });
                  } else {
                    setBankSelect(bank[0]);
                  }
                }}
              >
                <img
                  src={bank[1]}
                  className="transition-transform duration-300 hover:scale-110 hover:shadow-lg"
                  style={{
                    display: "block", // inline 요소 여백 문제 방지
                    boxShadow: "none", // 부모 요소 그림자 제거
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {addModal.show && (
        <BankAddModal
          open={addModal.show}
          onClose={() => setAddModal({ show: false })}
        />
      )}
    </>
  );
}
