import Text from "../../design-system/Text";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { useEffect, useState } from "react";
import { IconButton, Badge } from "@material-tailwind/react";
import CustomCheckBox from "../../design-system/CustomCheckBox";
import { addComma } from "../../../util/number";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import FillButton from "../../design-system/button/FillButton";
import { API_DEFAULT, API_SOCIAL, URL_HOST } from "../../../api/api";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { useLocation, useNavigate } from "react-router-dom";
import CartAddSelectorModal from "./CartAddSelectorModal";
import { CustomImage } from "../../design-system/CustomImg";
import {
  modalFetched,
  niceModalFetched,
} from "../../../store/reducer/globalModalSlice";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import FeedReportBtn from "./FeedReportBtn";
import { useContext } from "../../../util/useContext";
import PriceTicketInfoModal from "../../common/PriceTicketInfoModal";
import * as React from "react";
import PriceOwnerInfoModal from "../../common/PriceOwnerInfoModal";
import { useMediaQuery } from "react-responsive";
import { instance } from "../../../api";
import LocationInfoModal from "../../../pages/locationFeed/modal/LocationInfoModal";
import SellerInfo from "./SellerInfo";
import FeedComment from "./FeedComment";
import detailDate from "../../../util/dateHistoryTransfer";

export default function FeedContent({
  onClose,
  contentId,
  showAllKeywords,
  setShowAllKeywords,
  state,
}: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const { me, isLogin } = useContext();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { detail } = useAppSelector((state) => state.feed);
  const [cartModal, setCartModal] = useState({ show: false });
  const [priceInfoModal, setPriceInfoModal] = useState({ show: false });
  const [priceOwnerModal, setPriceOwnerModal] = useState({ show: false });
  const [loading, setLoading] = useState(false);
  const [ticket, setTicket] = useState<any>();
  const [owner, setOwner] = useState<any>();
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const [prevKeywords, setPrevKeywords] = useState<any>([]);
  const [infoModal, setInfoModal] = useState<any>({ show: false, data: "" });

  const handleTicketChange = (e: any) => {
    if (isLogin === false) {
      dispatch(modalFetched({ show: true }));
    } else {
      setTicket(e.target.checked);
      if (e.target.checked) {
        setOwner(false);
      }
    }
  };

  const handleOwnerChange = (e: any) => {
    if (isLogin === false) {
      dispatch(modalFetched({ show: true }));
    } else {
      setOwner(e.target.checked);
      if (e.target.checked) {
        setTicket(false);
      }
    }
  };

  async function cartFeedAdd() {
    const payload = {
      contentId: detail?.contentId,
      buyType: ticket ? "TICKET" : owner ? "OWNERSHIP" : "TICKET",
    };
    const res = await instance
      .post(`${API_DEFAULT}/user/cart/add`, payload)
      .then((response) => {
        if (response?.data.success) {
          dispatch(
            toastFetched({
              show: true,
              text: "장바구니에 담았습니다.",
              type: "success",
            })
          );
          setCartModal({ show: true });
          setLoading(false);
        }
      })
      .catch((error) => {
        dispatch(
          toastFetched({
            show: true,
            text: error.response.data.error.message,
            type: "error",
          })
        );
        setLoading(false);
      });
  }

  const handleCopyClipBoard = async (text: string) => {
    const setUrl = URL_HOST + text;
    try {
      await navigator.clipboard.writeText(setUrl);
      dispatch(
        toastFetched({
          show: true,
          text: "공유 링크가 복사되었습니다.",
          type: "success",
        })
      );
    } catch (e) {
      dispatch(
        toastFetched({
          show: true,
          text: "공유 링크 복사를 실패했습니다.",
          type: "error",
        })
      );
    }
  };

  const { data } = useQuery({
    queryKey: ["social-detail", detail],
    queryFn: async () =>
      await instance.get(`${API_SOCIAL}/user/social/get/detail`, {
        params: {
          contentId: detail?.contentId || state?.contentId || contentId,
          artistId: detail?.memberId || state?.memberId || contentId,
        },
      }),
  });

  async function userLike() {
    const payload = {
      contentId: detail?.contentId || state?.contentId || contentId,
    };
    const res = await instance.post(`${API_SOCIAL}/user/social/like`, payload);
  }

  const { mutate } = useMutation({
    mutationFn: userLike,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["social-detail"] });
    },
  });

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (detail?.keywords?.length > 10) {
      setPrevKeywords(detail?.keywords.slice(0, 10));
    }
  }, [detail]);

  return (
    <>
      <div
        className={`${isMobile ? "" : "max-w-[440px] w-[440px] min-h-[440px]"}`}
      >
        <Text className="feed-title ">{detail?.contentName}</Text>

        <div className="flex items-center gap-[10px] mt-4 mb-[30px]">
          <img src="/img/standbuy/icon/map-marker.svg" alt="map-marker" />
          {detail?.lat === null || detail?.lng === null ? (
            <Text className="feed-geo" color="#7B7B7B">
              등록된 위치 정보가 없습니다.
            </Text>
          ) : (
            <Text
              className="feed-geo cursor-pointer "
              color="#7B7B7B"
              onClick={() => {
                // setInfoModal({ show: true });
                // navigate("/location-feed");
                const currentPath = location.pathname;
                const targetPath = `/location-feed/${detail?.address ? detail?.address : detail?.contentName}`;
                if (currentPath === targetPath) {
                  if (location.pathname.includes("/share")) {
                    navigate(targetPath, {
                      state: {
                        data: {
                          lat: detail?.lat,
                          lng: detail?.lng,
                        },
                        keyword: detail?.address,
                      },
                    });
                  } else {
                    if (window.opener && !window.opener.closed) {
                      // 부모 창의 navigateToPage 함수 호출
                      window.opener.navigateToPage(targetPath, {
                        replace: true,
                        state: {
                          data: {
                            lat: detail?.lat,
                            lng: detail?.lng,
                          },
                          keyword: detail?.address,
                        },
                      });
                      window.close();
                    }
                  }
                } else {
                  if (location.pathname.includes("/share")) {
                    navigate(targetPath, {
                      state: {
                        data: {
                          lat: detail?.lat,
                          lng: detail?.lng,
                        },
                        keyword: detail?.address,
                      },
                    });
                  } else {
                    window.opener.navigateToPage(targetPath, {
                      replace: true,
                      state: {
                        data: {
                          lat: detail?.lat,
                          lng: detail?.lng,
                        },
                        keyword: detail?.address,
                      },
                    });
                    window.close();
                    // onClose();
                    moveToTop();
                  }
                  window.close();
                }
              }}
            >
              {detail?.address ? detail?.address : "지도에서 위치보기"}
            </Text>
          )}
        </div>
        <div className="max-w-[400px] flex flex-wrap gap-[10px]">
          {Array.isArray(detail?.keywords) && detail?.keywords?.length > 10 ? (
            <>
              {(showAllKeywords ? detail?.keywords : prevKeywords)?.map(
                (item: any, index: any) => {
                  return (
                    <div
                      key={index}
                      className="py-[6px] px-[10px] bg-gray-200 rounded-sm dark:bg-darkBanner
                      dark:!border-[#1a1a1a] dark:border-[1px] dark:border-solid
                      dark:placeholder:text-[#707070]"
                    >
                      <Text
                        color="#5E5E5E"
                        className="cursor-pointer "
                        onClick={() => {
                          sessionStorage.setItem(
                            "keywords",
                            JSON.stringify(item?.keyword)
                          );

                          if (location.pathname.includes("/share")) {
                            navigate(`/feed/filter`, {
                              state: { keyword: [item?.keyword] },
                              replace: true,
                            });
                          } else {
                            window.opener.navigateToPage(`/feed/filter`, {
                              state: { keyword: [item?.keyword] },
                              replace: true,
                            });
                            window.close();
                          }
                        }}
                      >
                        {item?.keyword}
                      </Text>
                    </div>
                  );
                }
              )}

              <>
                <button
                  onClick={() =>
                    showAllKeywords
                      ? setShowAllKeywords(false)
                      : setShowAllKeywords(true)
                  }
                  className="mt-2 text-blue-500"
                >
                  {!showAllKeywords ? "더보기" : "닫기"}
                </button>
              </>
            </>
          ) : (
            detail?.keywords?.map((item: any, index: any) => {
              return (
                <div
                  key={index}
                  className="py-[6px] px-[10px] bg-gray-200 rounded-sm dark:bg-darkBanner
                    dark:!border-[#1a1a1a] dark:border-[1px] dark:border-solid
                    dark:placeholder:text-[#707070]"
                >
                  <Text
                    color="#5E5E5E"
                    className="cursor-pointer "
                    onClick={() => {
                      sessionStorage.setItem(
                        "keywords",
                        JSON.stringify(item?.keyword)
                      );

                      if (location.pathname.includes("/share")) {
                        navigate(`/feed/filter`, {
                          state: { keyword: [item?.keyword] },
                          replace: true,
                        });
                      } else {
                        window.opener.navigateToPage(`/feed/filter`, {
                          state: { keyword: [item?.keyword] },
                          replace: true,
                        });
                        window.close();
                      }
                    }}
                  >
                    {item?.keyword}
                  </Text>
                </div>
              );
            })
          )}
        </div>

        <div
          className={`h-[1px] bg-gray-200 ${detail?.ownershipPrice === 0 && detail?.ticketPrice === 0 ? "mt-[13px]" : "my-[26px]"} dark:bg-gray-700`}
        ></div>

        <div className="flex flex-col gap-1  mb-[30px]">
          <div className="flex items-center gap-4">
            {detail?.ticketPrice === 0 ||
            detail?.ticketPrice === null ? null : (
              <>
                {/*<CustomCheckBox*/}
                {/*  flag={true}*/}
                {/*  checked={ticket}*/}
                {/*  onChange={handleTicketChange}*/}
                {/*  label={"이용권"}*/}
                {/*  disabled={detail?.soldOut ? true : false}*/}
                {/*/>*/}

                <div
                  className={"flex items-center justify-between w-full gap-2"}
                >
                  <div className="flex items-center gap-2">
                    <Text
                      className={`feed-price tooltip  ${
                        detail?.soldOut ? "line-through" : ""
                      }`}
                      color="#555"
                    >
                      ₩ {addComma(detail?.ticketPrice)}
                    </Text>

                    {isMobile ? null : (
                      <div
                        onClick={() => setPriceInfoModal({ show: true })}
                        className={"cursor-pointer"}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <path
                            d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
                            stroke="#C9C9C9"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12 16.5V12.5"
                            stroke="#C9C9C9"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12 8.5H12.0098"
                            stroke="#C9C9C9"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                  <Text
                    fontSize={isMobile ? 12 : 13}
                    fontWeight={600}
                    color="#7C7C7C"
                    className="mt-1"
                  >
                    {detailDate(new Date(detail?.createTime))}
                  </Text>
                </div>
              </>
            )}
          </div>

          {detail?.ticketPrice === 0 && (
            <div className="flex items-center gap-4 mb-[30px]">
              <Text fontSize={18} fontWeight={600}>
                오픈 콘텐츠로 무료로 다운로드가 가능합니다!
              </Text>
            </div>
          )}
        </div>
        <div className="flex items-center gap-4">
          <FeedReportBtn detail={detail} />
          <IconButton
            //@ts-ignore
            color="white"
            children={undefined}
            placeholder={undefined}
            size="lg"
            className="px-6 border !border-[#E1E1E1]"
            onClick={() => {
              if (isLogin && me?.mobileVerification) {
                handleCopyClipBoard(
                  `content/${window.btoa(detail?.contentId)}`
                );
              } else if (isLogin && !me?.mobileVerification) {
                dispatch(niceModalFetched({ show: true }));
              } else if (isLogin === false) {
                dispatch(modalFetched({ show: true }));
              }
            }}
          >
            <CustomImage src={"/img/standbuy/icon/share.svg"} color="#000" />
          </IconButton>
          <Badge content={data?.data.data.likesCount} withBorder>
            <IconButton
              //@ts-ignore
              onClick={() => {
                if (isLogin && me?.mobileVerification) {
                  mutate();
                } else if (isLogin && !me?.mobileVerification) {
                  dispatch(niceModalFetched({ show: true }));
                } else if (isLogin === false) {
                  dispatch(modalFetched({ show: true }));
                }
              }}
              color="white"
              children={undefined}
              placeholder={undefined}
              size="lg"
              className="px-6 border !border-[#E1E1E1]"
            >
              {data?.data.data.likeState ? (
                <FontAwesomeIcon
                  fontSize={20}
                  icon={solidHeart}
                  className={`${data?.data.data.likeState ? "text-red-500" : ""}`}
                />
              ) : (
                <FontAwesomeIcon fontSize={20} icon={faHeart} />
              )}
            </IconButton>
          </Badge>
          {detail?.soldOut ? (
            <FillButton
              disabled={false}
              onClick={() => {
                dispatch(
                  toastFetched({
                    show: true,
                    text: "소유권이 판매된 상품입니다.",
                    type: "error",
                  })
                );
              }}
              text="Sold Out"
              className="w-full"
              size="lg"
              color="red"
            />
          ) : (
            <FillButton
              // disabled={
              //   ticket ||
              //   owner ||
              //   (detail?.ownershipPrice === 0 && detail?.ticketPrice === 0)
              //     ? false
              //     : true
              // }
              onClick={() => {
                setLoading(true);
                cartFeedAdd();
              }}
              text="장바구니 담기"
              className={`${isMobile ? "flex-1" : "w-full"}`}
              size="lg"
              loading={loading}
              disabled={!isLogin}
            />
          )}
        </div>
      </div>

      {cartModal.show && (
        <CartAddSelectorModal
          open={cartModal.show}
          onClose={() => setCartModal({ show: false })}
        />
      )}

      {priceInfoModal.show && (
        <PriceTicketInfoModal
          open={priceInfoModal.show}
          onClose={() => setPriceInfoModal({ show: false })}
          noRemoveBtn={true}
        />
      )}

      {priceOwnerModal.show && (
        <PriceOwnerInfoModal
          open={priceOwnerModal.show}
          onClose={() => setPriceOwnerModal({ show: false })}
          noRemoveBtn={true}
        />
      )}

      {infoModal.show && (
        <LocationInfoModal
          open={infoModal.show}
          onClose={() => setInfoModal({ show: false })}
          data={infoModal.data}
        />
      )}
    </>
  );
}
