import React, { useEffect } from "react";
import { motion } from "framer-motion";
import Text from "../../components/design-system/Text";
import SideDrawer from "../../components/design-system/SideDrawer";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import GoogleAd from "../../components/ads/GoogleAd";
import { UploadTextColor } from "../../styles/color";
import { useLocation, useNavigate } from "react-router-dom";
import { MetaDatas } from "../../meta/MetaDatas";
import { useContext } from "../../util/useContext";
import { useMediaQuery } from "react-responsive";
import MobileFooter from "../../components/layout/footer/MobileFooter";

export default function MyPageLayout({ children, footer }: any) {
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const navigate = useNavigate();

  return (
    <>
      <div className="flex justify-between">
        {!isMobile && (
          <div className="w-[260px] flex flex-col items-center pt-[40px] border-r-[1px] border-gray-300 min-h-screen">
            <Text fontSize={24} fontWeight={600} className="pr-4 ">
              마이페이지
            </Text>

            <div className="w-[120px] flex flex-col justify-center gap-6 mt-[40px]">
              <Text
                fontSize={location.pathname === "/my-page" ? 18 : 14}
                fontWeight={location.pathname === "/my-page" ? 700 : 400}
                onClick={() => navigate("/my-page")}
                className="cursor-pointer "
              >
                내 정보 수정
              </Text>
              <Text
                fontSize={location.pathname === "/exchange" ? 18 : 14}
                fontWeight={location.pathname === "/exchange" ? 700 : 400}
                onClick={() => navigate("/exchange")}
                className="cursor-pointer "
              >
                정산
              </Text>
              <Text
                fontSize={location.pathname === "/exchange-detail" ? 18 : 14}
                fontWeight={
                  location.pathname === "/exchange-detail" ? 700 : 400
                }
                onClick={() => navigate("/exchange-detail")}
                className="cursor-pointer "
              >
                정산 내역
              </Text>
              <Text
                fontSize={location.pathname === "/fof-list" ? 18 : 14}
                fontWeight={location.pathname === "/fof-list" ? 700 : 400}
                onClick={() => navigate("/fof-list")}
                className="cursor-pointer "
              >
                결제 내역
              </Text>
              <Text
                fontSize={location.pathname === "/fof-credit" ? 18 : 14}
                fontWeight={location.pathname === "/fof-credit" ? 700 : 400}
                onClick={() => navigate("/fof-credit")}
                className="cursor-pointer "
              >
                콘텐츠 거래내역
              </Text>
            </div>
          </div>
        )}

        <div className="mt-[40px] w-full max-w-[1280px]  mx-auto relative">
          {children}
        </div>

        {footer}
      </div>
    </>
  );
}
