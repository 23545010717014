//@ts-nocheck

import { Select, Option } from "@material-tailwind/react";

const channelCode = [
  { code: "ko", name: "대한민국", lat: 37.56462664995, lng: 127.02878456871 },
  { code: "usa", name: "미국", lat: 40.7803, lng: -73.963 },
  { code: "jp", name: "일본", lat: 35.65494383172212, lng: 139.74536205548932 },
  { code: "uk", name: "영국", lat: 51.50072919999999, lng: -0.1246254 },
  {
    code: "fr",
    name: "프랑스",
    lat: 48.857681102802026,
    lng: 2.2953338014123688,
  },
  { code: "gr", name: "독일", lat: 52.4981944773883, lng: 13.406577186648331 },
  { code: "it", name: "이탈리아" },
  { code: "ca", name: "캐나다" },
];

export default function GlobalCountrySelect({
  country,
  setCountry,
  isFocused,
  setIsFocused,
}: any) {
  return (
    <Select
      label="대한민국"
      onClick={() => {
        setIsFocused(true);
      }}
      value={country}
      onChange={(val: any) => {
        setCountry(val);
      }}
      children={undefined}
      placeholder={undefined}
      size="lg"
      className="!bg-[#ffffff] border-[#121212]"
      animate={{
        mount: { y: 0 },
        unmount: { y: 25 },
      }}
      labelProps={{
        className: isFocused ? "hidden" : "",
      }}
    >
      {channelCode.map((bank) => (
        <Option key={bank.code} value={bank}>
          {bank.name}
        </Option>
      ))}
    </Select>
  );
}
