import React, { useEffect, useState } from "react";
import { useContext } from "../../util/useContext";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { useAxios } from "../../lib/api/useAxios";
import { API_DEFAULT } from "../../api/api";
import { setDetail } from "../../store/reducer/feedSlice";
import { useLocation, useNavigate } from "react-router-dom";
import AssetsMode from "../../components/feed/modal/AssetsMode";
import FeedContent from "../../components/feed/modal/FeedContent";
import SellerInfo from "../../components/feed/modal/SellerInfo";
import FeedComment from "../../components/feed/modal/FeedComment";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router";
import { instance } from "../../api";
import { MetaDatas } from "../../meta/MetaDatas";
import { FeedNav } from "../../components/design-system/FeedNav";
import { IconButton } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FeedMetaData } from "../../meta/FeedMetaData";

export default function SharePage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { detail } = useAppSelector((state) => state.feed);
  const params = useParams();
  const [showAllKeywords, setShowAllKeywords] = useState(false);
  const decodedContentId = window.atob(params?.contentId || "");

  async function onFetch() {
    const payload = {
      contentId: decodedContentId,
    };

    const res = await instance.get(`${API_DEFAULT}/contents/search/detail`, {
      params: payload,
    });
    if (res?.data.success) {
      dispatch(setDetail(res?.data.data));
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    onFetch();
  }, []);

  const keywordsString = detail?.keywords
    ?.map((item: any) => item.keyword)
    .join(", ");

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <FeedMetaData
        title={detail?.contentName}
        siteName={""}
        siteUrl={location.pathname}
        keywords={keywordsString}
        price={detail?.ticketPrice}
      />
      <div className="w-full flex flex-col h-full">
        <div
          className={`${isMobile ? "mt-4 px-4" : "mt-[50px] mx-auto"}  flex flex-col justify-center max-w-[1280px]`}
        >
          <div className={`flex ${isMobile && "flex-col"} gap-10`}>
            <AssetsMode />
            <FeedContent
              contentId={params?.contentId}
              showAllKeywords={showAllKeywords}
              setShowAllKeywords={setShowAllKeywords}
            />
          </div>
          <div>
            <SellerInfo detail={detail} state={params?.contentId} />
            <FeedComment detail={detail} state={params?.contentId} />
          </div>
        </div>

        <div
          className={`fixed bottom-10 ${isMobile ? "left-5" : "right-10"} z-[9999]`}
        >
          <div className="flex flex-col justify-center items-center gap-4 z-[9999]">
            {!isMobile && <FeedNav />}

            {!isMobile && (
              <IconButton
                //@ts-ignore
                className="rounded-full px-[28px] py-[28px]"
                placeholder={undefined}
                children={undefined}
                size="lg"
                onClick={moveToTop}
              >
                <FontAwesomeIcon icon={faArrowUp} />
              </IconButton>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
