import React from "react";
import CanvasDraw from "./card/\btool/CanvasDraw";
import styled from "styled-components";
import { NovaHeader } from "./card/NovaHeader";
const Wrap = styled.div`
  position: relative;
  background-image: url("/img/standbuy/background/nova/background-blob-service.png");
  background-size: cover;
`;

export default function NovaCanvasPage() {
  return (
    <Wrap className="min-h-screen flex flex-col items-center pt-[120px] bg-black pb-10">
      <NovaHeader />
      <CanvasDraw />
    </Wrap>
  );
}
