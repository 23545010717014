import React, { useEffect, useState } from "react";
import Text from "../../../components/design-system/Text";
import { useContext } from "../../../util/useContext";
import FillButton from "../../../components/design-system/button/FillButton";
import { Radio } from "@material-tailwind/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addBankInfo } from "../../../api/exchange/exchange";
import { useAppDispatch } from "../../../store/store";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { useMediaQuery } from "react-responsive";

const banks = [
  ["020", "/img/standbuy/bank/remove-bg/woori-bank.svg"],
  ["088", "/img/standbuy/bank/remove-bg/shinhan.svg"],
  ["034", "/img/standbuy/bank/remove-bg/gwangju.svg"],
  ["011", "/img/standbuy/bank/remove-bg/nh-bank.svg"],
  ["004", "/img/standbuy/bank/remove-bg/kb-bank.svg"],
  ["027", "/img/standbuy/bank/remove-bg/clit-bank.svg"],
  ["081", "/img/standbuy/bank/remove-bg/hana-bank.svg"],
  ["092", "/img/standbuy/bank/remove-bg/toss-bank.svg"],
  ["267", "/img/standbuy/bank/remove-bg/daesin-bank.svg"],
  ["090", "/img/standbuy/bank/remove-bg/kakao-bank.svg"],
  ["003", "/img/standbuy/bank/remove-bg/ibk-bank.svg"],
  ["035", "/img/standbuy/bank/remove-bg/jeju-bank.svg"],
  ["045", "/img/standbuy/bank/remove-bg/mg-bank.svg"],
  ["007", "/img/standbuy/bank/remove-bg/suhyup-bank.svg"],
  ["039", "/img/standbuy/bank/remove-bg/bnk-kn-bank.svg"],
  ["048", "/img/standbuy/bank/remove-bg/sinhyeob-bank.svg"],
  ["103", "/img/standbuy/bank/remove-bg/sbi-bank.svg"],
  ["037", "/img/standbuy/bank/remove-bg/jeonbuk-bank.svg"],
  ["071", "/img/standbuy/bank/remove-bg/post-bank.svg"],
  ["089", "/img/standbuy/bank/remove-bg/mk-bank.svg"],
  ["032", "/img/standbuy/bank/remove-bg/bnk-busan-bank.svg"],
  ["023", "/img/standbuy/bank/remove-bg/sc-bank.svg"],
  ["002", "/img/standbuy/bank/remove-bg/kdb-bank.svg"],
  ["etc", "/img/standbuy/bank/remove-bg/etc-bank.svg"],
];

export default function BankDetailAdd({
  bankSelect,
  setBankSelect,
  data,
}: any) {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const [accountNumber, setAccountNumber] = useState<any>();
  const [value, setValue] = useState<any>({
    person: false,
    company: false,
  });
  const [img, setImg] = useState<any>();

  const handleChange = (type: any) => {
    setValue({
      person: type === "person",
      company: type === "company",
    });
  };

  const { mutate, status } = useMutation({
    mutationFn: addBankInfo,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["profile-detail"] });
      if (data?.success) {
        window.location.reload();
        // onClose();
      } else {
        dispatch(
          toastFetched({
            show: true,
            text: data?.error.message,
            type: "error",
          })
        );
      }
    },
  });

  const selectedBankImage = banks.find(([code]) => code === bankSelect)?.[1];

  useEffect(() => {
    setImg(selectedBankImage);
  }, [bankSelect]);

  return (
    <div
      className={`border-[0.5px] border-[#D3D3D3] rounded-2xl min-h-[400px] py-10 px-12 mt-8 ${
        isMobile ? "px-6" : "px-12"
      }`}
    >
      <Text fontSize={isMobile ? 22 : 24} fontWeight={600}>
        계좌등록
      </Text>

      <div className="mt-10">
        <Text fontSize={isMobile ? 14 : 18} fontWeight={500}>
          2. 계좌번호와 예금주를 입력해주세요.
        </Text>

        <div className="flex flex-col items-center justify-center w-full">
          <div className="flex flex-col my-4">
            <div
              className={`flex gap-12 justify-between ${
                isMobile ? "flex-col" : ""
              }`}
            >
              <img
                src={img}
                alt="Selected Bank"
                className={`${isMobile ? "mt-4" : ""}`}
              />
              <div>
                <Radio
                  value={value.person}
                  onChange={() => handleChange("person")}
                  name="type"
                  label={
                    <Text
                      fontSize={14}
                      fontWeight={500}
                      color="#555"
                      className=""
                    >
                      개인
                    </Text>
                  }
                  crossOrigin={undefined}
                  ripple={true}
                  className="hover:before:opacity-0 checked:border-[#134B70] checked:before:bg-[#134B70] text-[#134B70]"
                  containerProps={{
                    className: "m-0",
                  }}
                />
                <Radio
                  value={value.company}
                  onChange={() => handleChange("company")}
                  name="type"
                  label={
                    <Text
                      fontSize={14}
                      fontWeight={500}
                      color="#555"
                      className=""
                    >
                      사업자
                    </Text>
                  }
                  ripple={true}
                  crossOrigin={undefined}
                  className="hover:before:opacity-0 checked:border-[#134B70] checked:before:bg-[#134B70] text-[#134B70]"
                />
              </div>
            </div>
          </div>
          <div
            className={`pt-4 pb-6 px-6  border-[0.5px] border-[#C5C5C5] rounded-2xl mt-4 ${
              isMobile ? "w-full" : "w-[350px]"
            }`}
          >
            <Text fontSize={18} fontWeight={500}>
              예금주
            </Text>

            <Text
              fontSize={18}
              fontWeight={500}
              className="flex justify-center "
            >
              {data?.data?.name}
            </Text>
          </div>

          <div
            className={`pt-4 pb-6 px-6 border-[0.5px] border-[#C5C5C5] rounded-2xl mt-6 ${
              isMobile ? "w-full" : "w-[350px]"
            }`}
          >
            <Text fontSize={18} fontWeight={500}>
              계좌번호
            </Text>

            <input
              type="text"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
              className="w-full mt-2 py-2 border-none outline-none"
              placeholder="계좌번호를 입력해주세요"
              maxLength={30}
            />

            <ul
              style={{
                listStyle: "inside",
                color: "#A7A7A7",
                fontSize: "12px",
                fontWeight: "500",
                marginTop: "6px",
              }}
            >
              <li>본인 명의의 계좌만 가능합니다.</li>
              <li>계좌번호는 숫자만 입력해주세요!</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="flex justify-center gap-2">
        <FillButton
          text="이전으로"
          color="gray"
          onClick={() => setBankSelect(undefined)}
          className="mt-8 rounded-full w-[140px] py-4 px-4 opacity-1 cursor-pointer bg-[#F4F4F4] text-black"
        />
        <FillButton
          text="완료"
          onClick={() => {
            const cleanedAccountNumber = String(accountNumber)?.replace(
              "-",
              ""
            );
            mutate({
              bankCode: bankSelect,
              accountNumber: cleanedAccountNumber,
              acctGb: value.person ? 1 : 2,
            });
          }}
          disabled={!accountNumber}
          className="mt-8 rounded-full w-[140px] py-4 px-4 opacity-1 cursor-pointer "
        />
      </div>
    </div>
  );
}
